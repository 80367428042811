import React, {useEffect, useState } from "react";  
import { Container, Row, Col, Form } from "react-bootstrap";
import Sidebar from "../sidebar/sidebar";
import axios from "axios";

function toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }

 
const baseURL = process.env.REACT_APP_BASE_URL;

const token = `Bearer ` + localStorage.getItem('token');
const userRole = localStorage.getItem('userRole');
// const dairyId = localStorage.getItem('dairyId');
         

const config = {
  headers: { 'Authorization': token,       
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
   }
};

const GeneratePayment = () => {

    const [dairyId, setdairyId] = useState(localStorage.getItem('dairyId'));
    const [startedDate , setstartedDate] = useState(toJSONLocal(new Date()));
    const [endDate , setendDate] = useState(toJSONLocal(new Date()));
    const [dairyData, setdairyData] = useState([]);
    const [payData, setpayData] = useState([]);
    const [disableGenerateButton, setdisableGenerateButton] = useState(true);
    const createdBy = localStorage.getItem('userId');
    const [totalcalculation, setTotalCalculation] = useState([]);
    const [SabhasadId, setSabhasadId] = useState([]);
    useEffect(() => {
        getDairyData(); 
        getdeductionDetails();
        // getPaymentData();
      },[]);
      const getDairyData = async () => {
        await axios.get(baseURL +"dairyregisters/", config).then(response => {
        setdairyData(response.data.message);         
         });
         }
         
        const getdeductionDetails = async () => { 
            var onamount = 0;  
            var onliter = 0;
           await axios.post(baseURL + "deductions/", {
                dairyId
            }, config)
            .then(              
                datadeduction => { 
                    for (var i=0; i < datadeduction.data.message.length; i++) {
                        if(datadeduction.data.message[i].deductionType === "On Amount Deduction") {
                            onamount = (parseFloat(onamount) + parseFloat(datadeduction.data.message[i].deductionValue));
                        } else {
                            onliter = parseFloat(onliter) + parseFloat(datadeduction.data.message[i].deductionValue);
                        } 
                }
                localStorage.setItem("onAmountDeduction", onamount);  
                localStorage.setItem("onLiterDeduction", onliter);
                }   
                        
            )
            .catch(err => console.log("This function is run when the request fails", err)) 
        }
        const getPaymentData = async () => {            
         axios.post(baseURL + "payments/", {
            dairyId, startedDate, endDate
        }, config).then(                
            dataPay => {
              Object.keys(dataPay.data.message).forEach(function(key) {
                const innerArray = dataPay.data.message[key];
               Object.keys(innerArray).forEach(function(key) {
                        if(key === "Feedsales"){
                        delete innerArray[key];
                        }if(key === "Collections"){
                            delete innerArray[key];
                        }
                        if(innerArray[key] === null) {
                            innerArray[key] = '0';
                        }
                               
                    })                             
            })
           var totweight = 0;
           var avgFAT = 0 ;
            var avgSNF = 0;
            var avgDegree = 0;
            var avgRate = 0;
            var subTotal = 0;
            var monthlyDeduction = 0;
            var totDeduction = 0;
            var  advanceAmount = 0;
            var  advanceReturn = 0;
            var advanceDeduction =0;
            var  feedSale = 0;
            var  feedSaleReturn=0;
            var  feedSaleDeduction =0;
            var  totalPayment= 0;
            var totrecord = 0 ;
            const SabhasadId = [];
                const addOtherParam = dataPay.data.message.map(object => {
                    SabhasadId.push(object.uid);
                    var deductionAmount = "0";
                    var totalDeductions = "0";
                    var advDeduction ="0";
                    var payamount = "0"; 
                    var subtot ="0";
                    var onAmountDeduction = localStorage.getItem('onAmountDeduction');
                    var onLiterDeduction = localStorage.getItem('onLiterDeduction');
                    avgFAT = (parseFloat(avgFAT) + parseFloat(object.avgFAT)).toFixed(2);
                    avgSNF = (parseFloat(avgSNF) + parseFloat(object.avgSNF)).toFixed(2);
                    avgDegree = (parseFloat(avgDegree) + parseFloat(object.avgDegree)).toFixed(2);
                    avgRate = (parseFloat(avgRate) + parseFloat(object.avgRate)).toFixed(2);
                    subTotal = (parseFloat(subTotal) + parseFloat(object.subTotal)).toFixed(2);
                    advanceAmount = (parseFloat(advanceAmount) + parseFloat(object.advanceAmount)).toFixed(2);
                    advanceReturn = (parseFloat(advanceReturn) + parseFloat(object.advanceReturn)).toFixed(2);
                    feedSale = (parseFloat(feedSale) + parseFloat(object.feed_saleAmount)).toFixed(2);
                    feedSaleReturn = (parseFloat(feedSaleReturn) + parseFloat(object.feed_PaidAmount)).toFixed(2);


                    if(object.subTotal !== null){
                        subtot = object.subTotal;
                        setdisableGenerateButton(false);
                        console.log(disableGenerateButton);
                    }
                    if(object.totalWeight !== "0") {
                        totrecord  = parseInt(totrecord) + 1;
                        totweight = parseFloat(totweight) + parseFloat(object.totalWeight) ; 
                        deductionAmount = (parseFloat(parseFloat(onLiterDeduction) * parseFloat(object.totalWeight)) + parseFloat(parseFloat(onAmountDeduction) * parseFloat(object.subTotal))).toFixed(2)  ;
                    } 
                    totalDeductions = (parseFloat(deductionAmount) + parseFloat(advDeduction) + parseFloat(object.feedSale)).toFixed(2);
                        payamount = (parseFloat(subtot) - parseFloat(totalDeductions)).toFixed(2);
                        if(payamount <= 0){
                            payamount = "0.00";  
                        }

                advanceDeduction = (parseFloat(advanceDeduction) + parseFloat(advDeduction)).toFixed(2);
                feedSaleDeduction = (parseFloat(feedSaleDeduction) + parseFloat(object.feedSale)).toFixed(2);
                monthlyDeduction = (parseFloat(monthlyDeduction) + parseFloat(deductionAmount)).toFixed(2);
                totalPayment = (parseFloat(totalPayment) + parseFloat(payamount)).toFixed(2);

    //  console.log("subtot "+subtot+"payamount " + payamount+" deductionAmount " + deductionAmount + " deductionLiter "+ onLiterDeduction + 
    //  " deductionAmount " + onAmountDeduction + " object.totalWeight " + object.totalWeight +
    //   " object.subTotal " + object.subTotal + " totalDeductions " + totalDeductions);

                return Object.assign(object, { startDate : startedDate , endDate : endDate,
                    dairyId: dairyId,
                    advanceDeduction: advDeduction, monthlyDeduction: deductionAmount,
                    payableAmount : payamount, totalDeduction : totalDeductions, createdBy :  createdBy, payDate : new Date()});
                });
                const sumofAll = [];
                avgSNF = (parseFloat(avgSNF) / parseFloat(totrecord)).toFixed(1);
                avgFAT = (parseFloat(avgFAT) / parseFloat(totrecord)).toFixed(1);
                avgDegree = (parseFloat(avgDegree) / parseFloat(totrecord)).toFixed(2);
                avgRate = (parseFloat(avgRate) / parseFloat(totrecord)).toFixed(2);
                subTotal = parseFloat(subTotal).toFixed(2);
                totweight = parseFloat(totweight).toFixed(2);
                totDeduction = parseFloat(totDeduction).toFixed(2);
                advanceAmount = parseFloat(advanceAmount).toFixed(2);
                advanceReturn = parseFloat(advanceReturn).toFixed(2);
                feedSale = parseFloat(feedSale).toFixed(2);
                feedSaleReturn = parseFloat(feedSaleReturn).toFixed(2);
                feedSaleDeduction = parseFloat(feedSaleDeduction).toFixed(2);
                totalPayment = parseFloat(totalPayment).toFixed(2);
                monthlyDeduction = parseFloat(monthlyDeduction).toFixed(2);

                Object.assign(sumofAll, {
                    startDate : startedDate,
                    endDate :endDate,
                    payDate : new Date(),
                    dairyId: dairyId,
                    totalWeight : totweight,
                    avgFAT : avgFAT,
                    avgSNF : avgSNF,
                    avgDegree: avgDegree,
                    avgRate: avgRate,
                    subTotal : subTotal,
                    monthlyDeduction :monthlyDeduction,
                    totDeduction : totDeduction,
                    advanceAmount : advanceAmount,
                    advanceReturn : advanceReturn,
                    advanceDeduction : advanceDeduction,
                    feedSale : feedSale,
                    feedSaleReturn: feedSaleReturn,
                    feedSaleDeduction : feedSaleDeduction,
                    totalPayment: totalPayment,
                    payStatus: "0",
                    createdBy: createdBy,
                })
                setTotalCalculation(sumofAll);
     setSabhasadId(SabhasadId);
                setpayData(addOtherParam);
            }
        ).catch(err => console.log("This function is run when the request fails", err))
        }

         const onChangeDiaryId = async (e) => {
           await setdairyId(e.target.value); 
            //  getPaymentData();
         }
         const inputEventStartDate= async (e)=>{
         await setstartedDate(e.target.value);
            // getPaymentData();
            };
        const inputEventendDate= async (e)=>{
            await setendDate(e.target.value);
        // getPaymentData();
        };

       
        const updateState = (e, index) => {
            const newArray = payData.map((item, i) => {              
              if (index === i) {
                const name = e.target.name ;
                const values = e.target.value;
                if(values === ""){
                    values = "0";
                }
                 var totDeduction =  parseFloat(parseFloat(item.feedSale) + parseFloat(item.monthlyDeduction) + parseFloat(item.advanceDeduction)).toFixed(2);
                 var d1 = (parseFloat(item.subTotal) - parseFloat(parseFloat(item.feedSale) + parseFloat(item.monthlyDeduction))).toFixed(2);  
                if( name === 'advanceDeduction' ){
                    if(values > item.balanceAdvance){
                        // alert("Advance Amount is less than deduction amount");
                        values = 0;
                    }
                     d1 = (parseFloat(item.subTotal) - parseFloat(parseFloat(item.feedSale) + parseFloat(item.monthlyDeduction) + parseFloat(values))).toFixed(2);                    
                     totDeduction =  parseFloat(parseFloat(item.feedSale) + parseFloat(item.monthlyDeduction) + parseFloat(values)).toFixed(2);
                    }   
                if(name==='feedSale'){
                    if(values > item.feed_saleAmount){
                        // alert("Feed Sale is less than deduction amount");
                        values = 0;
                    }
                    d1 = (parseFloat(item.subTotal) - parseFloat(parseFloat(values) + parseFloat(item.monthlyDeduction) + parseFloat(item.advanceDeduction))).toFixed(2);                    
                    totDeduction =  parseFloat(parseFloat(values) + parseFloat(item.monthlyDeduction) + parseFloat(item.advanceDeduction)).toFixed(2);
                }

                return { ...item, payableAmount : d1,
                                  totalDeduction : totDeduction,
                                 [e.target.name]: values };
              } else {
                return item;
              }
            });           
            setpayData(newArray);   
            console.log(newArray);         
          };

    const submitData = async () => {

const paymentParamenter = {
    startDate : startedDate,
    endDate :endDate,
    payDate : new Date(),
    dairyId: dairyId,
    totalWeight : totalcalculation.totalWeight,
    avgFAT : totalcalculation.avgFAT,
    avgSNF : totalcalculation.avgSNF,
    avgDegree: totalcalculation.avgDegree,
    avgRate: totalcalculation.avgRate,
    subTotal : totalcalculation.subTotal,
    monthlyDeduction : totalcalculation.monthlyDeduction,
    totDeduction : totalcalculation.totDeduction,
    advanceAmount : totalcalculation.advanceAmount,
    advanceReturn : totalcalculation.advanceReturn,
    advanceDeduction : totalcalculation.advanceDeduction,
    feedSale : totalcalculation.feedSale,
    feedSaleReturn: totalcalculation.feedSaleReturn,
    feedSaleDeduction : totalcalculation.feedSaleDeduction,
    totalPayment: totalcalculation.totalPayment,
    payStatus: "0",
    createdBy: createdBy,
} ;
       console.log(paymentParamenter);   
        axios.post(baseURL + "payments/create/", paymentParamenter, config)
        .then(async data =>{
           if(data.data.statuscode === "200")
           { const payId = data.data.payId;
            const paydatavalues = payData.map(object => {
                return Object.assign(object, {payId: data.data.payId});
                });
                await axios.post(baseURL + "payments/generatepayment", paydatavalues , config).then(paymentdata =>{
                   if(paymentdata.data.statuscode === "200"){  
                        axios.post(baseURL + "payments/updatecollectionstatus/", { SabhasadId , dairyId, startedDate, endDate, payId }, config)
                            .then(data =>{
                                if(data.data.statuscode === "200"){        
                                    window.location.href = '/payments';
                                } 
                            })
                            .catch(error => console.log(error));  
                                    } 
                })                 
          } 
        }).catch(error => console.log(error)); 
    }


    return (
        <>
        <Sidebar />
        <Container>
            
        <Row>
                <Col>
                <div className="right-side">
                      <div className="input_text">
                      <Form.Select aria-label="Dairy Name Select" name="dairyId" value={dairyId} onChange={onChangeDiaryId} 
                      disabled={userRole === "User" ? true : false}
                     >
                        <option value=""> Please Select Dairy  </option>
                      { dairyData.map((e, key) => {  
                              return <option key={key} value={e.id} >{e.dairyName}</option>;  
                              })} 
                    </Form.Select>
                    </div>                         
                    </div>
                </Col>
                <Col>
                <div className="right-side">
                      <div className="input_text">
                      <label> From Date</label>
                      <input type="date" id="datepicker" value={startedDate} onChange={inputEventStartDate} />
                    </div>                         
                    </div>
                </Col>
                <Col>
                <div className="right-side">
                      <div className="input_text">
                      <label> To Date</label>
                      <input type="date" id="datepicker" value={endDate} onChange={inputEventendDate}  />
                    </div>                         
                    </div>
                </Col>
                <Col>
                <button type="button" className="btn btn-success" onClick={getPaymentData}> Generate PDF </button>
                </Col>
              </Row>
<Row>
    <Col>
            <div className="row">
                <div className="col-sm-12 tscroll">
                    <table className="table col-sm-12 paymentTable">
                        <thead >
                            <tr>
                                <th scope="col">SabhaSad Number</th>
                                <th scope="col">SabhaSad Name</th>
                                <th scope="col">Milk Type</th>
                                <th scope="col">Total Milk Weight</th>
                                <th scope="col">Average SNF</th>
                                <th scope="col">Average FAT</th>
                                <th scope="col">Average Degree</th>
                                <th scope="col">Average Rate</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col"> Deduction</th>
                                <th scope="col">Feeds Sale</th>
                                <th scope="col">Feed Amount Paid</th>
                                <th scope="col">Feed Deduction</th>
                                <th scope="col">Advance </th>
                                <th scope="col">Advance Return</th>
                                <th scope="col">Advance Deduction </th>
                                <th scope="col">Total Deduction </th>
                                <th scope="col">Payable Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                        {         
                                    payData.length
                                    ?
                                    payData.map((payment, index) => (
                                       <tr key={index}>
                                           <td> {payment.udfId} </td>
                                            <td > { payment.firstName + ' '+  payment.lastName}</td>
                                            <td > { payment.milkType }</td>
                                            <td > { payment.totalWeight !== null ? parseFloat(payment.totalWeight).toFixed(2) : '0.00'  }</td>
                                            <td > { payment.avgSNF !== null ? parseFloat(payment.avgSNF).toFixed(1)  : '0.0' }</td>
                                            <td > { payment.avgFAT !== null ? parseFloat(payment.avgFAT).toFixed(1) : '0.0' }</td>
                                            <td > { payment.avgDegree !== null ? parseFloat(payment.avgDegree).toFixed(2) : '0.0' }</td>
                                            <td > { payment.avgRate !== null ? parseFloat(payment.avgRate).toFixed(2) : '0.0'  }</td>
                                            <td > { payment.subTotal !== null ? parseFloat(payment.subTotal).toFixed(2) : '0.0' }</td>
                                            <td>{payment.monthlyDeduction}</td>
                                            <td> { payment.feed_saleAmount !== null ? parseFloat(payment.feed_saleAmount).toFixed(2) : '0.0' }</td>
                                            <td>{ payment.feed_PaidAmount !== null ? parseFloat(payment.feed_PaidAmount).toFixed(2) : '0.0' }</td>
                                            <td><input type="number" step={0.50} value={payment.feedSale} name="feedSale"   onChange={e => updateState(e, index)} /></td>
                                            <td>{ payment.advanceAmount !== null ? parseFloat(payment.advanceAmount).toFixed(2) : '0.0' }</td>
                                            <td>{ payment.advanceReturn !== null ? parseFloat(payment.advanceReturn).toFixed(2) : '0.0' }</td>
                                            <td><input type="number" step={0.50} value={payment.advanceDeduction} name="advanceDeduction"   onChange={e => updateState(e, index)} /></td>
                                            <td>{payment.totalDeduction}</td>                                           
                                            <td>{payment.payableAmount}</td>


                                
                            </tr>
                                    ))
                                    : 
                                    <tr>
                                        <td colSpan="18" className="text-center">No Data Found.</td>
                                    </tr> 
}
                        <tr>
                            <td colSpan={3}> Total </td>
                            <td>{totalcalculation.totalWeight}</td>
                            <td>{totalcalculation.avgSNF}</td>
                            <td>{totalcalculation.avgFAT}</td>
                            <td>{totalcalculation.avgDegree}</td>
                            <td>{totalcalculation.avgRate}</td>
                            <td>{totalcalculation.subTotal}</td>
                            <td>{totalcalculation.monthlyDeduction}</td>
                            <td>{totalcalculation.feedSale}</td>
                            <td>{totalcalculation.feedSaleReturn}</td>
                            <td>{totalcalculation.feedSaleDeduction}</td>
                            <td>{totalcalculation.advanceAmount}</td>
                            <td>{totalcalculation.advanceReturn}</td>
                            <td>{totalcalculation.advanceDeduction}</td>
                            <td>{totalcalculation.totDeduction}</td>
                            <td>{totalcalculation.totalPayment}</td>

                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>  
                     
    </Col>
</Row>
<Row>
<button type="submit" disabled={disableGenerateButton}  onClick={submitData}  className="btn btn-primary">Generate Payment</button>
</Row>

        </Container>
           
        </>

    );
}

export default GeneratePayment;
