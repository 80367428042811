import React from "react";
import Signin from '../login/signin';

function Logout() {
    localStorage.clear();
    return(
    <>
    <Signin />
    </>
    );
}
export default Logout;