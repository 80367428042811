import React, {useEffect, useState} from "react";
import Sidebar from '../sidebar/sidebar';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container, ProgressBar } from "react-bootstrap";
import axios from "axios";

  
  const token = `Bearer ` + localStorage.getItem('token');
  const dairyId = localStorage.getItem('dairyId');
  const baseURL = process.env.REACT_APP_BASE_URL;
  const config = {
    headers: { 'Authorization': token,       
      'Access-Control-Allow-Origin' : '*',
      'Content-Type': 'application/json',
     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
     'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
     }
  };
  




function Dashboard() {
  const [CollectionData, setCollectionData] = useState([]);
 
   useEffect(() => {

    const getCollectionData = async () => {
      await axios.post(baseURL +"collections/todayscollections/",{
       dairyId :dairyId,
       milkShift: "M"
      }, config).then(response => {  
        // console.log(response.data.message.totalSabhasad);
        // return response.data.message;    
        setCollectionData(response.data.message);  
       });
       }           
      // console.log(CollectionData.totalSabhasad );
     getCollectionData(); 
   },[]);

    return(
        <>
        <Sidebar />
        <Container>
          
        <div> Welcome to Laxmee Doodh Milk Collection System </div>
       
       <Row>
        <Col>
        <ProgressBar>
          <ProgressBar striped variant="success" now={(CollectionData.totalSabhasad / CollectionData.totalSabhasad) * 100} key={1} label={`संकलन ${1}%`} />
          <ProgressBar striped variant="warning" now={100} key={2} label={`सभासद ${100}%`} />
        </ProgressBar>
        </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}>
        <Link to="/addsabhasad"><Button variant="warning" style = {{backgroundColor:'orange'}} > नवीन सभासद जोडा  </Button></Link> 
          
          </Col>
          <Col xs={12} md={2}>
        <Link to="/collections"><Button variant="warning" style = {{backgroundColor:'orange'}} > दूध संकलन  </Button></Link> 
          
          </Col>
          <Col xs={12} md={2}>
        <Link to="/feedsales"><Button variant="warning" style = {{backgroundColor: 'orange'}} > पशुखाद्य विक्री रजिस्टर  </Button></Link> 
          
          </Col>
          <Col xs={12} md={2}>
        <Link to="/advance"><Button variant="warning" style = {{backgroundColor:'orange'}} > Advance / आगाऊ पैसे देणे </Button></Link> 
          
          </Col>
          <Col xs={12} md={2}>
        <Link to="/payments"><Button variant="warning" style = {{backgroundColor:'orange'}} > Payment / पगार  </Button></Link> 
          
          </Col>
          <Col xs={12} md={2}>
        <Link to="/ratechart"><Button variant="warning" style = {{backgroundColor:'orange'}}> दुध दर पत्रक </Button></Link> 
          </Col>
          <Col xs={12} md={2}>
        <Link to="/logout"><Button variant="warning" style = {{backgroundColor:'orange'}}> Logout </Button></Link> 
          </Col>
        </Row> 
                 
        </Container>
          
        <Row className={"fixed-row-bottom"}>
          <Col xs={12} md={6}>
         <div>  Design And Developed By <a href="https://sanyogsoft.com">Sanyog Software </a>  </div>
          </Col>
          <Col xs={12} md={6}>
          <div>Contact On  <a href="mailto:support@sanyogsoft.com">Email Us</a> / +91 7028456777
             </div>
          </Col>
          </Row>
      </>
    );
}
export default Dashboard;