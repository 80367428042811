import React, {Component} from 'react';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import Sidebar from '../sidebar/sidebar';
import axios from 'axios';
// import { json } from 'react-router-dom';

 
const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');

const config = {
     headers: { Authorization: token,     
      'Access-Control-Allow-Origin' : '*',
      'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
    }   
};
function testClickEvent(param) {
  window.location.href = '/feededit/'+param;
}
export default class AddSabhasad extends Component {
    constructor(props){
        super(props);
        this.state={feedsList : [],
            hasError: false};        
    }

    async getUsersData(){
        const res = await axios.get(baseURL + "feeds/" , config)
        const array = res.data.message;
        array.map(function(e){
          e.clickEvent = () => testClickEvent( e.id);
     });
        this.setState({ feedsList: array}); 
      }
      
      componentDidMount(){
        this.getUsersData()
      }
    
    render() {          
        const data = () => {
            return {
          columns: [
            {
              label: 'Sr. No.',
              field: 'id',
              width: 150,
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Sabhasda Number',
              },
            },
            {
              label: 'Feed Name',
              field: 'feedName',
              width: 270,
            },
            {
              label: 'Feed Rate',
              field: 'feedRate',
              width: 200,
            },
          ],
          rows: this.state.feedsList,
        }
    }
        return(
            <>
            <Sidebar />
            <div className='container'>
            <div className="card">          
        <div className="card-body">
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    bordered
                    hover
                    responsive                    
                    scrollX
                    scrollY
                    entriesOptions={[10, 20, 50, 100]}
                    entries={10}
                    pagesAmount={4}
                    data={data()}
                    materialSearch={true}
                    // onClick={(e) => testClickEvent(e, JSON.stringify(data["rows"]["id"]))}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
            </div>
            </div>
            </div>
            </>
        );
    }

}
