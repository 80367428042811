import React from "react";
import { useNavigate } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Sidebar from '../sidebar/sidebar'; 
import axios from "axios";

function FeedAdd() {
const{useState}=React;

const[inputs,setinputs]=useState({
  feedName : "",
  feedRate: "",
  createdBy :"",
updatedBy : "",  
Message : "",

});
// const navigate = useNavigate();

const[warnfeedName,setwarnfeedName]=useState(false);
const[warnfeedRate,setewarnfeedRate]=useState(false);

const[danger,setdanger]=useState(true);
const[Message,setMessage]=useState(false);

const navigate = useNavigate();
const inputEvent= (event)=>{
const name=event.target.name;
const value=event.target.value;
if(name === "username"){
if(value.length>0){
setdanger(true);
}
}
setinputs((lastValue)=>{
return{
...lastValue,
[name]:value
}
});
};

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');

// const dairyId = localStorage.getItem('dairyId');
const config = {
  headers: { 'Authorization': token,       
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
   }   
};

const feedName = inputs.feedName;
const feedRate = inputs.feedRate;
const createdBy = localStorage.getItem('userId');
// const updatedBy = localStorage.getItem('userId');

const bodyParameters = {
feedName: feedName,
feedRate : feedRate,
createdBy : createdBy

};


const submitForm = async (event) =>{
    event.preventDefault();
setMessage("");
setwarnfeedName(false);
setewarnfeedRate(false);
if(feedName === "" ){ setwarnfeedName(true); } 
if(feedRate === "" ){ setewarnfeedRate(true); } 
if( feedName !=="" && feedRate !== ""){
  axios
  .post(baseURL + "feeds/register/",  
   bodyParameters, config)
  .then(data =>{
        if(data.data.statuscode === "200"){        
      navigate("/feedslist");
      } 
    })
  .catch(error => console.log(error));
  }
}      
    return(
    <>
    <Sidebar />
        <div className="container">          
        <div className="card">          
        <div className="card-body form">
      <form autoComplete="off" onSubmit={submitForm}>
        <Row>
            <Col xs={12} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <input className={` ${warnfeedName ? "warning" : "form-control" }`} type="text" placeholder="Enter Feed name" autoComplete="off" name="feedName" value={inputs.feedName} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Feed Name </p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <input className={` ${warnfeedRate ? "warning" : "form-control" }`} type="text" placeholder="Enter Feed rate" autoComplete="off" name="feedRate" value={inputs.feedRate} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Feed Rate</p>
                            </div>                         
                    </div>
            </Col>            
        </Row> 
            <div>
                <button type="submit" className="btn btn-primary">Save Feed</button>
            </div>
        {Message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {Message}
                        </div>
                        </div>
                    )}         
        </form> 
        </div>    
        </div>
        </div>



    </>
    );
}
export default FeedAdd;