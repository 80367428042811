import React, {Component} from 'react';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import Sidebar from '../sidebar/sidebar';
import axios from 'axios';

 
const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const dairyId = localStorage.getItem('dairyId');

const config = {
     headers: { 'Authorization': token,        
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
    }   
};
const bodyParameters = {
  dairyId  :dairyId
  
  };

export default class DeductionList extends Component {
    constructor(props){
        super(props);
        this.state={deductionList : [],
            hasError: false};        
    }
  
    async getDeductionData(){
        const res = await axios.post(baseURL + 'deductions' ,  bodyParameters, config)
        this.setState({ deductionList: res.data.message });        
      }
      componentDidMount(){
        this.getDeductionData()
      }
    
    render() {

        const data = () => {
            return {
                  columns: [
            {
              label: 'Sr. No.',
              field: 'id',
              width: 150,
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Sr. No.',
              },
            },
            {
              label: 'Deduction Name',
              field: 'deductionTitle',
              width: 270,
            },
            {
              label: 'Deduction Type',
              field: 'deductionType',
              width: 200,
            },
            {
              label: 'Deduction Value',
              field: 'deductionValue',
              width: 200,
            },
          ],
          rows: this.state.deductionList,

        }
    }

        return(
            <>
            <Sidebar />
            <div className='container'>
        
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    bordered
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
            </div>
            </>
        );
    }

}
