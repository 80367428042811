import React, {useEffect} from "react";
import Sidebar from '../sidebar/sidebar';
import { Container, Row, Col, Form } from "react-bootstrap";
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from 'axios';


function UserRegister() {
    const{useState}=React;
    const[inputs,setinputs]=useState({
      firstName : "",
      chairmanName : "",
      lastName : "",
      dairyPhone : "",
      email : "",
      username : "",
      password : "",
      allDairyList : [],
    })
    
    

    const [dairyName, setdairyName] = useState("");
    const[warnfirstName, setwarnfirstName]=useState(false);
    const[warnchairmanName, setwarnchairmanName]=useState(false);
    const[warnlastName, setwarnlastName]=useState(false);
    const[warndairyPhone, setwarndairyPhone]=useState(false);
    const[warndairyName, setwarndairyName]=useState(false);
    const[warndairyAddress, setwarndairyAddress]=useState(false);
    const[warnemail, setwarnemail]=useState(false);
    const[warnusername, setwarnusername]=useState(false);
    const[warnpassword, setwarnpassword]=useState(false);
    const [allUsersList, setallUsersList] = useState("");
    const[updateMethod, setupdateMethod]=useState(false);
    const[submitButtonName, setsubmitButtonName]=useState("Create New User");
    const[dairyId, setdairyId]=useState("");
    const[userId, setuserId]=useState("");
    const[dairyStatus, setdairyStatus]=useState("0");
    const[dairyData, setdairyData]=useState([]);
    const [dairyAddress, setdairyAddress] = useState("");


    const[danger,setdanger]=useState(true);
    const[Message,setMessage]=useState(false);

    const inputEvent= (event)=>{
        const name=event.target.name;
        const value=event.target.value;
        if(name === "username"){
        if(value.length>0){
        setdanger(true);
        }
        }
        setinputs((lastValue)=>{
        return{
        ...lastValue,
        [name]:value
        }
        });
        };
    const data = () => {
        return {
columns: [
        {
          label: 'Sr. No.',
          field: 'id',
          width: 75,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Sr. No.'
          }
        },
        {
          label: 'First Name',
          field: 'firstName',
          width: 150,
        },
        {
          label: 'Last Phone',
          field: 'lastName',
          width: 150,
        },{
          label: 'Dairy Name',
          field: 'dairyName',
          width: 250,
        }, 
        ,{
            label: 'UserName',
            field: 'username',
            width: 150,
          }        
      ],
      rows : allUsersList
    }
}

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

useEffect(() => {
    getUserRegister();
    getDairyData();
  }, [])

  const getUserRegister = async () =>{
    const res = await axios.get(baseURL + "users/" , config)
    const array = res.data.message;
    array.map(function(e){
      e.clickEvent = () => testClickEvent( e.id);
  });
  setallUsersList(array) ;
  }

  
  const submitForm = async (event) =>{
event.preventDefault();
setMessage("");
setwarnlastName(false);
setwarndairyName(false);
setwarnfirstName(false);
setwarndairyPhone(false);
setwarnemail(false);
setwarndairyAddress(false);
setwarnusername(false);
setwarnpassword(false);

    const firstName = inputs.firstName;
    const lastName = inputs.lastName ;
    const dairyPhone = inputs.dairyPhone ;
    // const  dairyName  = dairyName ;
    // const dairyStatus  =  dairyStatus ;
    // const maxDegree  =  inputs.maxDegree ;
    // const dairyAddress  =  dairyAddress ;
    const email  =  inputs.email ;
    const username  =  inputs.username ;
    const password  =  inputs.password ;
    const chairmanName  =  inputs.chairmanName;
    const createdBy = localStorage.getItem('userId');


if(firstName === "" ){ setwarnfirstName(true); } 
if(dairyPhone === "" ){ setwarndairyPhone(true); } 
if(dairyName === "" ){ setwarndairyName(true); } 
if(chairmanName === "" ){ setwarnchairmanName(true); } 
if( firstName !=="" && dairyPhone !== "" && dairyName !== ""){
    const bodyParameters = {
        firstName: firstName,
        lastName:lastName ,
        dairyId :dairyId,
        dairyPhone:dairyPhone ,
        dairyName :dairyName ,
        dairyStatus : dairyStatus ,
        dairyAddress : dairyAddress ,
        email : email ,
        username : username ,
        password : password ,
        chairmanName : chairmanName,
        createdBy : createdBy
        
    }

if (updateMethod) {
axios
.put(baseURL +"users/"+ userId,  
 bodyParameters, config)
.then(data =>{
      if(data.data.statuscode === "200"){     
      clearAllInputs();
      getUserRegister();
      setdairyId("");
      setuserId("");
      setupdateMethod(false);
      setsubmitButtonName("Create New User");
    } 
  })
.catch(error => console.log(error));
} else {    
axios
.post(baseURL +"users/register",  
bodyParameters, config)
.then(data =>{
    // console.log("data "+ data + " statuscode "+ data.data.statuscode);
    if(data.data.statuscode === "200"){     
    clearAllInputs();
    getUserRegister();

  } 
})
.catch(error => console.log(error));
}  
}
}  
function clearAllInputs(){
    setinputs(()=>{
      return{
      firstName:"",
      lastName:"",
      dairyPhone:"",
      email : "",
      username : "",
      password : "",
      chairmanName : "",

      }}
      )
      setsubmitButtonName("Create User Register ");
    }
  
  function testClickEvent(param) {
    // clearAllInputs();
    axios.get(baseURL + "users/" + param , config).then(
      data =>{
      setinputs(()=>{
        return {
        firstName: data.data.message.firstName,
        dairyPhone: data.data.message.dairyPhone,
        lastName : data.data.message.lastName,
        chairmanName : data.data.message.chairmanName,       
        email: data.data.message.email,
        username: data.data.message.username,
        password: data.data.message.password,
        }
        });
        setdairyStatus(data.data.message.dairyStatus);
        setdairyName(data.data.message.dairyName);
        setdairyAddress( data.data.message.dairyAddress);
        setdairyId(data.data.message.dairyId);
        setuserId(data.data.message.id);
        setupdateMethod(true);
        setsubmitButtonName("Update User Register ");
  
    })
    .catch();  
  }
  
  async function onChangedairyStatus(e) {
    setdairyStatus(e.target.value);
  } 
  async function onChangedairyId(e) {
    setdairyId(e.target.value);
    setdairyAddress(e.target.selectedOptions[0].getAttribute('data-address'));
    setdairyName(e.target.selectedOptions[0].label);
  } 
  async function getDairyData() {
    await axios.get(baseURL +"dairyregisters/", config).then(response => {
     setdairyData(response.data.message);
     });
     }
    return(
        <>
        <Sidebar />
        <Container>
            <Row>
                <Col xs={12} md={5}>
                <h4 className="text-center"> User List</h4> 
                <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    striped
                    bordered
                    hover
                    responsiveSm
                    scrollX
                    scrollY
                    entriesOptions={[10, 20, 25, 50, 100, 1000]}
                    entries={10}
                    pagesAmount={4}
                    maxHeight="50vh"
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
                </Col>
                <Col xs={12} md={7}>
                <h4 className="text-center"> {submitButtonName }</h4>
         <form autoComplete="off" onSubmit={submitForm}>
            <Row>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > First Name </label>
                                <input className={` ${warnfirstName ? "warning" : "form-control" }`} type="text" placeholder="Enter First Name " autoComplete="off" name="firstName" value={inputs.firstName ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter First Name.</p>
                            </div>                         
                    </div>
            </Col>            
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Last Name </label>
                                <input className={` ${warnlastName ? "warning" : "form-control" }`} type="text" placeholder="Enter Dairy Address " autoComplete="off" name="lastName" value={inputs.lastName ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Dairy Address.</p>
                            </div>                         
                    </div>
            </Col>
            
            </Row>
            <Row>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Select Dairy </label>
                                <Form.Select aria-label=" Select Dairy " name="dairyId" onChange={onChangedairyId} value={dairyId} >
                                <option value="" data-address=""> Please Select Dairy  </option>
                           { dairyData.map((e, key) => {  
                                    return <option key={key} value={e.id}  data-address={e.dairyAddress}>{e.dairyName}</option>;  
                                    })} 
                </Form.Select>
                </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Dairy Name </label>
                                <input className={` ${warndairyName ? "warning" : "form-control" }`} type="text" placeholder="Enter Dairy Name " autoComplete="off" name="dairyName" value={dairyName} readOnly/>
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Dairy Name</p>
                            </div>                         
                    </div>
            </Col>
            
            </Row>
            <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Dairy Address </label>
                                <input className={` ${warndairyAddress ? "warning" : "form-control" }`} type="text"  placeholder="Enter Dairy Address " autoComplete="off" name="dairyAddress" value={dairyAddress} readOnly/>
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Dairy Address.</p>
                            </div>                         
                    </div>
            </Col>

            </Row>            
            <Row>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Dairy Phone </label>
                                <input className={` ${warndairyPhone ? "warning" : "form-control" }`} type="text" placeholder="Enter Phone Number " autoComplete="off" name="dairyPhone" value={inputs.dairyPhone ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Dairy Phone </p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label >Email  </label>
                                <input className={` ${warnemail ? "warning" : "form-control" }`} type="text"  placeholder="Enter Email " autoComplete="off" name="email" value={inputs.email ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Email</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > User Name </label>
                                <input className={` ${warnusername ? "warning" : "form-control" }`} type="text"  placeholder="Enter User Name/ Mobile Number" autoComplete="off" name="username" value={inputs.username ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter User Name.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Password </label>
                                <input className={` ${warnpassword ? "warning" : "form-control" }`} type="text"  placeholder="Enter Password " autoComplete="off" name="password" value={inputs.password ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Password.</p>
                            </div>                         
                    </div>
            </Col>
            </Row>
            <Row>                 
            <Col xs={12} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Chairman Name </label>
                                <input className={` ${warnchairmanName ? "warning" : "form-control" }`} type="text" placeholder="Enter Chairman Name " autoComplete="off" name="chairmanName" value={inputs.chairmanName ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Customer Code.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > User Status </label>
                                <Form.Select aria-label=" Status Select" name="dairyStatus" onChange={onChangedairyStatus} >
                            <option value="0">In Active</option>
                            <option value="1">Active</option>
                          </Form.Select>
                            </div>                         
                    </div>
            </Col>       
            </Row>
            
        <Row>
            <Col md={6} xs={0}>
            </Col>
            <Col md={3} xs={6}>
            <button type="button" className="btn btn-warning" onClick={clearAllInputs}> Clear </button>
            </Col>
            <Col md={3} xs={6}>
            <button type="submit" className="btn btn-primary">{submitButtonName}</button>                
            </Col>
        </Row>
        {Message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {Message}
                    </div>
                    </div>
        )}

            </form>
                </Col>
            </Row>
            </Container>
      </>
    );
}
export default UserRegister;