import React, {Component} from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import Sidebar from '../sidebar/sidebar';


const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const dairyId = localStorage.getItem('dairyId');
const config = {
     headers: { Authorization: token,     
      'Access-Control-Allow-Origin' : '*',
      'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
    }   
};

export default class Feedsales extends Component {
  constructor(props){
    super(props);
    this.state={ 
    warnudfId: "",
    udfId : "",
    uid : "",
    fullName : "Name",
    feedName : "",
    feedId : "",
    feedRate : "",
  warnfeedrate : "",
  warnquantity : "",
  quantity : "1",
  totalAmount : "0.00",
  warntotalAmount : "",
  feedAmount : "0.00",
  warnfeedAmount : "",
  paidBy : "Cash",
  warnpaidBy : "",
  feedData : [],
        hasError: false};        
}
async handleInputChange(e) {
  await this.setState({
    [e.target.name] : e.target.value
  });

  if(e.target.name === "feedRate" || e.target.name ==="quantity" ) {
    await this.setState({
      totalAmount : this.state.feedRate * this.state.quantity 
  });
} 
if(e.target.name === "udfId") {
  const udfId = this.state.udfId
  await axios.post(baseURL +"sabhasads/sabhasad", {
    dairyId,
    udfId   
  },config).then(response => {
    if(response.data.statuscode === "200"){
      this.setState({
        fullName: response.data.message.firstName + " " + response.data.message.lastName,
        uid : response.data.message.id
        });
    }  
    });
} 
}

async handleChangeSelect(e){
  await this.setState({
    [e.target.name] : e.target.value,
    feedRate : e.target.selectedOptions[0].getAttribute('data-rate'),
    feedName : e.target.selectedOptions[0].label,
    // totalAmount : this.state.feedRate * this.state.quantity 
  }); 
  
  await this.setState({
    totalAmount : this.state.feedRate * this.state.quantity 
});
}
  async componentDidMount() {
 await axios.get(baseURL +"feeds/", config).then(response => {
  this.setState({
  feedData: response.data.message
  });
  });
  }
  

  render() {

    const bodyParameters = {
      dairyId : dairyId,
      uid : this.state.uid,
      udfId : this.state.udfId,
      fullName : this.state.fullName,
      feedId : this.state.feedId,
      feedName : this.state.feedName,
      quantity :this.state.quantity,
      feedRate : this.state.feedRate,
      totalAmount : this.state.totalAmount ,
      paidAmount : this.state.paidAmount,
      paidBy : this.state.paidBy,
      saleDate : new Date(),
      createdBy : localStorage.getItem('userId'),
    }
    
    const submitForm = async (event) =>{
      event.preventDefault();
      axios
      .post(baseURL + "feeds/feedsale/",  
       bodyParameters, config)
      .then(data =>{
            if(data.data.statuscode === "200"){        
              window.location.href = '/feedsalelist';
          } 
        })
      .catch(error => console.log(error));
    }

    return(
      <>
      <Sidebar />
       <div className="container">
       <div className="card">          
        <div className="card-body form">
        <form autoComplete="off"  onSubmit={submitForm}>
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label> Sabhasad Number</label>
                                <input className={` ${this.state.warnudfId ? "warning" : "form-control" }`} type="text" placeholder="Enter Sabhasad Id" autoComplete="off" name="udfId" value={this.state.udfId}  onChange={e  => this.handleInputChange(e)} />
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={8}>                    
                    <div className="right-side">
                            <div className="input_text">
                               <label>Farmer Name</label>
                               <input  type="text" placeholder="Enter Sabhasad name" autoComplete="off" name="fullName" value={this.state.fullName}  onChange={e  => this.handleInputChange(e)} />

                                 </div>                         
                    </div>
            </Col>            
        </Row> 
        <Row>
            <Col xs={12} md={3}>                    
                    <div className="right-side">
                    <div className="input_text">
                    <label>Feed Name</label>
                    <Form.Select aria-label="Feed Slect"  value={this.state.feedId}  name="feedId" onChange={e  => this.handleChangeSelect(e)} >
                         <option value=""> Please Select Feed </option>
                            {this.state.feedData.map((e, key) => {  
return <option key={key} value={e.id} data-rate={e.feedRate}>{e.feedName}</option>;  
})} 
                          </Form.Select>  
                          </div>                     
                    </div>
            </Col>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label>Feed Rate</label>
                            <input className={` ${this.state.warnfeedRate ? "warning" : "form-control" }`} type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="100.0" autoComplete="off" name="feedRate" value={this.state.feedRate} onChange={e  => this.handleInputChange(e)} />
                             </div>                         
                    </div>
            </Col>  
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label>Quantity</label>
                            <input className={` ${this.state.warnquantity ? "warning" : "form-control" }`} type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="1.00" autoComplete="off" name="quantity" value={this.state.quantity} onChange={e  => this.handleInputChange(e)} />
                            </div>                         
                    </div>
            </Col>  
            <Col xs={12} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label>Amount</label>
                            <input className={` ${this.state.warntotalAmount ? "warning" : "form-control" }`} type="text" placeholder="Enter Amount" autoComplete="off" name="totalAmount" value={this.state.totalAmount} onChange={e  => this.handleInputChange(e)} />
                            </div>                         
                    </div>
            </Col>            
        </Row> 
        <Row>
          
        <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label>Paid Amount</label>
                            <input className={` ${this.state.warnpaidAmount ? "warning" : "form-control" }`} type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="100.0" autoComplete="off" name="paidAmount" value={this.state.paidAmount} onChange={e  => this.handleInputChange(e)} />
                             </div>                         
                    </div>
            </Col>  
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                    <div className="input_text">
                    <label>Paid By </label>
                    <Form.Select aria-label="Paid By"  value={this.state.paidBy}  name="paidBy" onChange={e  => this.handleInputChange(e)} >
                           <option value="Cash">Cash </option>
                           <option value="UPI">UPI </option>
                          </Form.Select>  
                          </div>                     
                    </div>
            </Col>
            </Row>
            <div>
                <button type="submit" className="btn btn-primary">Save Feed</button>
            </div>
        </form>
        </div>
        </div>
      
      </div>
    </>
  );
  }
    
}