import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import { Form, Row , Col} from 'react-bootstrap';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import Sidebar from '../sidebar/sidebar';
import axios from 'axios';
import { Link } from "react-router-dom";

 
const baseURL = process.env.REACT_APP_BASE_URL;

const token = `Bearer ` + localStorage.getItem('token');
const userRole = localStorage.getItem('userRole');
// const dairyId = localStorage.getItem('dairyId');
         

const config = {
  headers: { 'Authorization': token,       
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
   }
};
export default class RateChart extends Component {
    constructor(props){
        super(props);
        this.state={
          dairyId :  localStorage.getItem('dairyId'),
          milkType : "C",
          collectionShift : "M",
          rateCharts : [],
          dairyData : [],
          hasError: false
        };    
        //this.delta = this.delta.bind(this);      
    }
    
    

    
async getRatesData(){
        const res =  await axios.post(baseURL + "ratecharts/" , 
        {
          dairyId: this.state.dairyId,
          milkType : this.state.milkType,
          collectionShift : this.state.collectionShift,

        }, config )
        const array = res.data.message;
            this.setState({ rateCharts: array });        
      }
      
      async getDairyData() {
        await axios.get(baseURL +"dairyregisters/", config).then(response => {
         this.setState( { dairyData : response.data.message});
         });
         }


        async delta(e) {
          await this.setState({
            [e.target.name] : e.target.value
          });       
        this.getRatesData();
      }

      componentDidMount(){
        this.getRatesData();
        this.getDairyData();
      }
    
    render() {

        const data = () => {
            return {
                  columns: [
            {
              label: 'Id',
              field: 'id',
              width: 75,
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Id',
              },
            },
            {
              label: 'FAT',
              field: 'fat',
              sort : 'asc',
              width: 100,
              
            },
            { label: '7.1', field: 'S70', sort: 'disabled', width: 100 },
            { label: '7.1', field: 'S71',  sort: 'disabled', width: 100 },
            { label: '7.2', field: 'S72',  sort: 'disabled', width: 100, },
            { label: '7.3',  field: 'S73',  sort: 'disabled', width: 100, },  
            { label: '7.4',  field: 'S74', sort: 'disabled', width: 100,  },  
            { label: '7.5',  field: 'S75', sort: 'disabled',  width: 100, },    
            { label: '7.6',  field: 'S76',  sort: 'disabled', width: 100,  },  
            { label: '7.7', field: 'S77', sort: 'disabled',  width: 100, },
            { label: '7.8',  field: 'S78', sort: 'disabled', width: 100,  },  
            { label: '7.9',   field: 'S79',  sort: 'disabled', width: 100, }, 
            { label: '8.0',  field: 'S80', sort: 'disabled', width: 100,  }, 
            { label: '8.1', field: 'S81', width: 100 },
            { label: '8.2', field: 'S82',  sort: 'disabled', width: 100, },
            { label: '8.3',  field: 'S83',  sort: 'disabled', width: 100, },  
            { label: '8.4',  field: 'S84', sort: 'disabled', width: 100,  },  
            { label: '8.5',  field: 'S85', sort: 'disabled',  width: 100, },    
            { label: '8.6',  field: 'S86',  sort: 'disabled', width: 100,  },  
            { label: '8.7', field: 'S87', sort: 'disabled',  width: 100, },
            { label: '8.8',  field: 'S88', sort: 'disabled', width: 100,  },  
            { label: '8.9',   field: 'S89',  sort: 'disabled', width: 100, }, 
            { label: '9.0',  field: 'S90', sort: 'disabled', width: 100,  }, 
            { label: '9.1', field: 'S91', width: 100 },
            { label: '9.2', field: 'S92',  sort: 'disabled', width: 100, },
            { label: '9.3',  field: 'S93',  sort: 'disabled', width: 100, },  
            { label: '9.4',  field: 'S94', sort: 'disabled', width: 100,  },  
            { label: '9.5',  field: 'S95', sort: 'disabled',  width: 100, },    
            { label: '9.6',  field: 'S96',  sort: 'disabled', width: 100,  },  
            { label: '9.7', field: 'S97', sort: 'disabled',  width: 100, },
            { label: '9.8',  field: 'S98', sort: 'disabled', width: 100,  },  
            { label: '9.9',   field: 'S99',  sort: 'disabled', width: 100, }, 
            { label: '10.0',  field: 'S100', sort: 'disabled', width: 100,  },  
            { label: '10.1', field: 'S101', width: 100 },
            { label: '10.2', field: 'S102',  sort: 'disabled', width: 100, },
            { label: '10.3',  field: 'S103',  sort: 'disabled', width: 100, },  
            { label: '10.4',  field: 'S104', sort: 'disabled', width: 100,  },  
            { label: '10.5',  field: 'S105', sort: 'disabled',  width: 100, },    
            { label: '10.6',  field: 'S106',  sort: 'disabled', width: 100,  },  
            { label: '10.7', field: 'S107', sort: 'disabled',  width: 100, },
            { label: '10.8',  field: 'S108', sort: 'disabled', width: 100,  },  
            { label: '10.9',   field: 'S109',  sort: 'disabled', width: 100, }, 
            { label: '11.0',  field: 'S110', sort: 'disabled', width: 100,  }, 
            { label: '11.1', field: 'S111', width: 100 },
            { label: '11.2', field: 'S112',  sort: 'disabled', width: 100, },
            { label: '11.3',  field: 'S113',  sort: 'disabled', width: 100, },  
            { label: '11.4',  field: 'S114', sort: 'disabled', width: 100,  },  
            { label: '11.5',  field: 'S115', sort: 'disabled',  width: 100, },    
            { label: '11.6',  field: 'S116',  sort: 'disabled', width: 100,  },  
            { label: '11.7', field: 'S117', sort: 'disabled',  width: 100, },
            { label: '11.8',  field: 'S118', sort: 'disabled', width: 100,  },  
            { label: '11.9',   field: 'S119',  sort: 'disabled', width: 100, }, 
            { label: '12.0',  field: 'S120', sort: 'disabled', width: 100,  }, 
            
          ],
          rows: this.state.rateCharts,

        }
    }
            return(
            <>
            <Sidebar />
            <Container>
              <Row>
                <Col>
                <div className="right-side">
                      <div className="input_text">
                      <Form.Select aria-label="Dairy Name Select" name="dairyId" value={this.state.dairyId} onChange={this.delta.bind(this)} 
                      disabled={userRole === "User" ? true : false}
                     >
                        <option value=""> Please Select Dairy  </option>
                      { this.state.dairyData.map((e, key) => {  
                              return <option key={key} value={e.id} >{e.dairyName}</option>;  
                              })} 
                    </Form.Select>
                    </div>                         
                    </div>
                </Col>
                <Col>
                <div className="right-side">
                      <div className="input_text">
                      <Form.Select aria-label="Select Milk" name="milkType" value={this.state.milkType} onChange={this.delta.bind(this)} 
                      >
                        <option value="C"> Cow Milk </option>
                        <option value="B"> Buffalow Milk </option>
                    </Form.Select>
                    </div>                         
                    </div>
                </Col>
                <Col>
                <div className="right-side">
                      <div className="input_text">
                      <Form.Select aria-label="Select Collection Shift" name="collectionShift" value={this.state.collectionShift} onChange={this.delta.bind(this)} 
                      >
                        <option value="M"> Morning </option>
                        <option value="E"> Evening </option>
                    </Form.Select>
                    </div>                         
                    </div>
                </Col>                
                <Col>
                <div className="right-side">
                      <div className="text-end">
                       <Link to="/generateratechart" > Create New rate Chart </Link>
              
                    </div>                         
                    </div>
               </Col>
              </Row>
              <Row>

              </Row>
            <Row>
              <Col>
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable small
                    striped
                    bordered
                    hover
                    responsiveSm
                    scrollX
                    scrollY
                    entriesOptions={[10, 20, 25, 50, 100, 1000]}
                    entries={50}
                    pagesAmount={4}
                    maxHeight="50vh"
                    data={data()}
                    materialSearch={true}
                    className="text-end"
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
            </Col>
          </Row>
            </Container>
            </>
        );
    }

}
