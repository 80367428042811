import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import { Row,Col,Form } from 'react-bootstrap';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import Sidebar from '../sidebar/sidebar';
import axios from 'axios';

 
const baseURL = process.env.REACT_APP_BASE_URL;

const token = `Bearer ` + localStorage.getItem('token');
const userRole = localStorage.getItem('userRole');

// const dairyId = localStorage.getItem('dairyId');
function testClickEvent(param) {
  window.location.href = '/paymentdetails/'+param;
}

const config = {
  headers: { 'Authorization': token,       
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
   }
};
// const bodyParameters = {
//   dairyId: dairyId
// };
export default class PaymentList extends Component {
    constructor(props){
        super(props);
        this.state={
         paymentList : [],
          dairyData : [],
          dairyId : localStorage.getItem('dairyId'),
          hasError: false};
          this.delta = this.delta.bind(this);
    }

    async getUsersData(){
        const res = await axios.post(baseURL + "payments/listall/" , {
          dairyId: this.state.dairyId
        }, config )
        const array = res.data.message;
           array.map(function(e){
        return e.clickEvent = () => testClickEvent( e.id);
            });
            this.setState({ paymentList: array });        
      }
      componentDidMount(){
        this.getUsersData();
        this.getDairyData() ;
      }
      async getDairyData() {
        await axios.get(baseURL +"dairyregisters/", config).then(response => {
         this.setState( { dairyData : response.data.message});
         });
         }
         
         delta = async (e) => {   
          await this.setState({
            dairyId: e.target.value
          });
          this.getUsersData();
        }
    
    render() {

        const data = () => {
            return {
                  columns: [
            {
              label: 'Sr. No',
              field: 'id',
              width: 75,
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Sr. No',
              },
            },
            {
              label: 'Payment Date',
              field: 'payDate',
              width: 100,
            },
            {
              label: 'Date From',
              field: 'startDate',
              width: 100,
            },
            {
                label: 'Date To',
                field: 'endDate',
                width: 100,
              },
            {
              label: 'Total Milk',
              field: 'totalWeight',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Average Rate',
              field: 'avgRate',
              sort: 'disabled',
              width: 150,
            },  
            {
                label: 'Sub Total',
                field: 'subTotal',
                sort: 'disabled',
                width: 150,
              },  
              {
                label: 'Total Payment',
                field: 'totalPayment',
                sort: 'disabled',
                width: 150,
              },            
          ],
          rows: this.state.paymentList,

        }
    }
            return(
            <>
            <Sidebar />
            <Container>
            <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <Form.Select aria-label="Dairy Name Select" name="dairyId" value={this.state.dairyId} onChange={this.delta} 
                            disabled={userRole === "User" ? true : false}
                            >
                              <option value=""> Please Select Dairy  </option>
                           { this.state.dairyData.map((e, key) => {  
                                    return <option key={key} value={e.id}  >{e.dairyName}</option>;  
                                    })} 
                          </Form.Select>
                                 </div>                         
                    </div>
            </Col>
              <Col>
              
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    striped
                    bordered
                    hover
                    responsiveSm
                    scrollX
                    scrollY
                    entriesOptions={[10, 20, 25, 50, 100, 1000]}
                    entries={10}
                    pagesAmount={4}
                    maxHeight="50vh"
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
            </Col>
          </Row>
            </Container>
            </>
        );
    }

}
