import React, {Component} from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import Sidebar from '../sidebar/sidebar';


const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
// const dairyId = localStorage.getItem('dairyId');
const config = {
     headers: { Authorization: token,     
      'Access-Control-Allow-Origin' : '*',
      'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
    }   
};

export default class FeedEdit extends Component {
  constructor(props){
    super(props);
    this.state={ 
         warnfeedName: "",
         warnfeedRate : "",
    feedName : "",
    feedRate : "",
        hasError: false};        
}
async handleInputChange(e) {
  await this.setState({
    [e.target.name] : e.target.value
  }); 
}

componentDidMount() {
  axios.get(baseURL +"feeds/"+ window.location.href.split('/')[4], config).then(response => {
  this.setState({
  feedName: response.data.message.feedName,
  feedRate : response.data.message.feedRate
  });
  });
  }
  

  render() {

    const bodyParameters = {
      feedName : this.state.feedName,
      feedRate : this.state.feedRate,
      updatedBy :localStorage.getItem('userId'),
    }
    
    const submitForm = async (event) =>{
      event.preventDefault();
      axios
      .put(baseURL + "feeds/" +window.location.href.split('/')[4] ,  
       bodyParameters, config)
      .then(data =>{
        console.log("data" + data);
            if(data.data.statuscode === "200"){        
              window.location.href = '/feedslist';
          } 
        })
      .catch(error => console.log(error));
    }

    return(
      <>
      <Sidebar />
       <div className="container">
       <div className="card">          
        <div className="card-body form">
        <form autoComplete="off"  onSubmit={submitForm}>
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label> Sabhasad Number</label>
                                <input className={` ${this.state.warnfeedName ? "warning" : "form-control" }`} type="text" placeholder="Enter Feed Name" autoComplete="off" name="feedName" value={this.state.feedName}  onChange={e  => this.handleInputChange(e)} />
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={8}>                    
                    <div className="right-side">
                            <div className="input_text">
                               <label>Farmer Name</label>
                               <input  type="text" placeholder="Enter Sabhasad name" autoComplete="off" name="feedRate" value={this.state.feedRate}  onChange={e  => this.handleInputChange(e)} />

                                 </div>                         
                    </div>
            </Col>            
        </Row> 
            <div>
                <button type="submit" className="btn btn-primary">Update Feed</button>
            </div>
        </form>
        </div>
        </div>
      
      </div>
    </>
  );
  }
    
}