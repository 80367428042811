import React, {useEffect, useState} from "react";
import Sidebar from '../sidebar/sidebar';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container, ProgressBar } from "react-bootstrap";
import axios from "axios";
import './calender.css';

  
  const token = `Bearer ` + localStorage.getItem('token');
  const dairyId = localStorage.getItem('dairyId');
  const baseURL = process.env.REACT_APP_BASE_URL;
  const config = {
    headers: { 'Authorization': token,       
      'Access-Control-Allow-Origin' : '*',
      'Content-Type': 'application/json',
     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
     'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
     }
  };
  




function Calender() {
  const [CollectionData, setCollectionData] = useState([]);
 
   useEffect(() => {

    const getCollectionData = async () => {
      await axios.post(baseURL +"collections/todayscollections/",{
       dairyId :dairyId,
       milkShift: "M"
      }, config).then(response => {  
        // console.log(response.data.message.totalSabhasad);
        // return response.data.message;    
        setCollectionData(response.data.message);  
       });
       }     

       
      console.log(CollectionData.totalSabhasad );
     getCollectionData(); 
   },[]);

    return(
        <>
        <Sidebar />
        <Container>
          
        <div> Welcome to Laxmee Doodh Milk Collection System </div>
       
        <Col>
        <Row>
        <ProgressBar>
          <ProgressBar striped variant="success" now={(CollectionData.totalSabhasad / CollectionData.totalSabhasad) * 100} key={1} label={`संकलन ${1}%`} />
          <ProgressBar striped variant="warning" now={100} key={2} label={`सभासद ${100}%`} />
        </ProgressBar>
        </Row>
        <Row>
            <Col>
            <img src ="../src/assets/images/laxmee.jpg" alt="Header Image"/>
            </Col>
        </Row>
        <Row>
            <Col xs={2} className="border">
            <span className="dayname">रविवार</span> 
            </Col>
            <Col xs={2} className="border">
2
          </Col>
            <Col xs={2} className="border">
                3
            </Col>
            <Col xs={2} className="border">
                4
            </Col>
            <Col xs={2} className="border">
                5
            </Col>
            <Col xs={2} className="border">
                6
            </Col>
        </Row>
        <Row>
            <Col xs={2} className="border">
Monday
            </Col>
            <Col xs={2} className="border">
2
          </Col>
            <Col xs={2} className="border">
                3
            </Col>
            <Col xs={2} className="border">
                4
            </Col>
            <Col xs={2} className="border">
                5
            </Col>
            <Col xs={2} className="border">
                6
            </Col>
        </Row>
        <Row>
            <Col xs={2} className="border">
Tuesday
            </Col>
            <Col xs={2} className="border">
2
          </Col>
            <Col xs={2} className="border">
                3
            </Col>
            <Col xs={2} className="border">
                4
            </Col>
            <Col xs={2} className="border">
                5
            </Col>
            <Col xs={2} className="border">
                6
            </Col>
        </Row>
        <Row>
            <Col xs={2} className="border">
WednesDay
            </Col>
            <Col xs={2} className="border">
2
          </Col>
            <Col xs={2} className="border">
                3
            </Col>
            <Col xs={2} className="border">
                4
            </Col>
            <Col xs={2} className="border">
                5
            </Col>
            <Col xs={2} className="border">
                6
            </Col>
        </Row>
        <Row>
            <Col xs={2} className="border">
Thuresday
            </Col>
            <Col xs={2} className="border">
2
          </Col>
            <Col xs={2} className="border">
                3
            </Col>
            <Col xs={2} className="border">
                4
            </Col>
            <Col xs={2} className="border">
                5
            </Col>
            <Col xs={2} className="border">
                6
            </Col>
        </Row>
        <Row>
            <Col xs={2} className="border">
Friday
            </Col>
            <Col xs={2} className="border">
2
          </Col>
            <Col xs={2} className="border">
                3
            </Col>
            <Col xs={2} className="border">
                4
            </Col>
            <Col xs={2} className="border">
                5
            </Col>
            <Col xs={2} className="border">
                6
            </Col>
        </Row>
        <Row>
            <Col xs={2} className="border">
Saturday
            </Col>
            <Col xs={2} className="border">
2
          </Col>
            <Col xs={2} className="border">
                3
            </Col>
            <Col xs={2} className="border">
                4
            </Col>
            <Col xs={2} className="border">
                5
            </Col>
            <Col xs={2} className="border">
                6
            </Col>
        </Row>
        </Col>        
        </Container>
          
      </>
    );
}
export default Calender;