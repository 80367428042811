import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Col, Row } from "react-bootstrap";
import Sidebar from '../sidebar/sidebar'; 
import axios from "axios";

function toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }

function CollectionEntry() {
const{useState}=React;
const[inputs,setinputs]=useState({
  farmerName: "",
  milkWeight: "",
  milkSNF: "",
  milkDegree: "",
  milkFAT: "",
  milkRate : "0.00",
  milkWater : "0",
  milkAmount : "",
  collectionDate : toJSONLocal(new Date()),
  dairyId : "",
  createdBy :"",
  warnudfId : "",
  
Message : "",

});
// const navigate = useNavigate();

const stateRef = useRef();
stateRef.milkFAT = inputs.milkFAT;
stateRef.milkSNF = inputs.milkSNF;

const[warnfarmerName,setwarnfarmerName]=useState(false);
const[warnmilkWeight, setwarnmilkWeight]=useState(false);
const[warnmilkSNF,setwarnmilkSNF]=useState(false);
const[warnmilkDegree,setwarnmilkDegree]=useState(false);
const[warnmilkFAT,setwarnmilkFAT]=useState(false);
const[warnmilkRate,setwarnmilkRate]=useState(false);
const[warnmilkWater,setwarnmilkWater]=useState(false);
const[warncollectionDate,setwarncollectionDate]=useState(false);
const [prevudfId, setprevudfId] = useState([...Array(200)].map((x, i) => i + 1));
const[warnmilkAmount, setwarnmilkAmount]=useState(false);
const [warnudfId, setwarnudfId] = useState("");
const [uid, setuid] = useState("0");
const [nightSwitch, setnightSwitch] = useState("Morning");
const [MilkTypeSwitch, setMilkTypeSwitch] = useState("Cow");
const [milkShift, setmilkShift] = useState("M");
const [milkType, setmilkType] = useState("C");

const[danger,setdanger]=useState(true);
const[Message,setMessage]=useState(false);

const navigate = useNavigate();
const inputEvent= (event)=>{
const name=event.target.name;
let value = event.target.value;
const maxLength= event.target.maxLength;
const nextFocus1=event.target.className;
const nextFocus = nextFocus1.replace('form-control ', '')
console.log('nextFocus' + nextFocus + " maxLength" +maxLength + "Event "+ event.target.maxLength);
// const { maxLength, value, name } = event.target;
if((name === "milkFAT") || (name === "milkSNF")){
    if(value.length === 1){
        value = event.target.value + "."  ;
    }
    else if((inputs.milkFAT.length === 3 ) && (inputs.milkSNF.length === 3)){
        getRateBYFatSNF();
    }
    // console.log("OUTER stateRef.milkSNF" + stateRef.milkSNF + " stateRef.milkFAT "+ stateRef.milkFAT);

}
if(name === "milkDegree"){
    if(value.length === 2){
        value = event.target.value + "."  ;
    }
}
setinputs((lastValue)=>{
return{
...lastValue,
[name]:value
}
});

if(name=== "udfId"){
    getSabhasadbyUdfId();
    console.log(inputs.collectionDate);
}
if (value.length >= maxLength) {
    const nextfield = document.querySelector(
        `input[name=${nextFocus}]`
      );

      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      }
}

};

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');

const dairyId = localStorage.getItem('dairyId');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};


useEffect(() => {    
        // async function fetchMyAPI() {
        //         await  axios.post(baseURL + "sabhasads/sabhasadidbydairy",
        //         {
        //                 dairyId: dairyId
        //         }, config)
        //         .then(data => {
        //                 const newArr = [];
        //                 if (data.data.statuscode === "200") {
        //                         const arr = data.data.message;
        //                         Object.keys(arr).forEach(function (key) {
        //                                 newArr.push(parseInt(arr[key]['udfId']));
        //                         }
        //                         );
        //                         var array1 = [];
        //                         array1 = [...Array(200)].map((x, i) => i + 1);
        //                         array1 = array1.filter(function (val) {
        //                                 return newArr.indexOf(val) === -1;
        //                         });
        //                         setprevudfId(array1);
        //                         setudfId(array1[0]);
        //                 }
        //         })
        //         .catch(error => console.log(error));
        // }
        // fetchMyAPI();
}, []);

const bodyParameters = {
udfId : inputs.udfId,
uid : uid.toString(),
dairyId: dairyId,
farmerName:inputs.farmerName,
milkWeight:inputs.milkWeight,
milkFAT:inputs.milkFAT,
milkDegree:inputs.milkDegree,
milkSNF:inputs.milkSNF,
milkRate : inputs.milkRate.toString(),
milkAmount :inputs.milkAmount,
milkWater: inputs.milkWater,
collectionDate : inputs.collectionDate,
collectionTime : "10:00",
milkShift: milkShift,
milkType : milkType,
payStatus : "0",
deleteStatus : "0",
createdBy : localStorage.getItem('userId'),
updatedBy :localStorage.getItem('userId'),

};

const getRateBYFatSNF = async () =>{
    console.log("stateRef.milkSNF" + stateRef.milkSNF + " stateRef.milkFAT "+ stateRef.milkFAT);
    axios
    .post(baseURL +"ratecharts/getrate",  
     {
        dairyId, 
        SNF : parseFloat(inputs.milkSNF) * 10,
        fat :inputs.milkFAT, collectionShift : "M", 
        milkType : "C"
     }, config)
    .then(data =>{
          if(data.data.statuscode === "200"){     
            setinputs((lastValue)=>{
                return{
                ...lastValue,
                milkRate:  data.data.rate ,
                milkAmount : parseFloat(parseFloat(data.data.rate) * parseFloat(inputs.milkWeight)).toFixed(2),
                }
                });
               console.log(data.data.rate)
               console.log(dairyId + inputs.milkSNF + inputs.milkFAT);
        } 
      })
    .catch(error => console.log(error));
console.log("DairyId"  + dairyId, "FAT " + inputs.milkFAT);
}

const submitForm = async (event) =>{
    event.preventDefault();
    console.log(bodyParameters);
setMessage("");
setwarnfarmerName(false);
setwarnmilkWeight(false);
setwarnmilkSNF(false);
setwarnmilkFAT(false);
setwarnmilkDegree(false);
setwarnmilkWater(false);
setwarnmilkAmount(false);
setwarnmilkRate(false);
setwarncollectionDate(false);
if(inputs.farmerName === "" ){ setwarnfarmerName(true); } 
if(inputs.milkDegree === "" ){ setwarnmilkDegree(true); } 
if(inputs.milkSNF === "" ){ setwarnmilkSNF(true); } 
if(inputs.milkFAT === "" ){ setwarnmilkFAT(true); } 
if( inputs.udfId !=="" && inputs.farmerName !== "" && inputs.milkDegree !== "" && inputs.milkFAT !=="" && inputs.milkSNF !==""){
  axios
  .post(baseURL +"collections/entry",  
   bodyParameters, config)
  .then(data =>{
        if(data.data.statuscode === "200"){        
      navigate("/collections");
      } 
    })
  .catch(error => console.log(error));
  }
}  
// async function onChangeudfId(e) {
//     setudfId(e.target.value);
//        await getSabhasadbyUdfId();
// } 

// async function updateudfId(e){
//    setudfId(e.target.value);
// }
async function getSabhasadbyUdfId(){
    console.log(inputs.udfId);
    await axios.post(baseURL +"sabhasads/sabhasad", {
      dairyId,
      udfId : inputs.udfId   
    },config).then(async response => {
      if(response.data.statuscode === "200"){
      setinputs((lastValue)=>{
            return{
            ...lastValue,
            farmerName : response.data.message.firstName + " " + response.data.message.lastName
            }
            });

    //   await setinputs({
    //     farmerName : response.data.message.firstName + " " + response.data.message.lastName
    //    });
       await  setuid(response.data.message.id);
      }  
      });
}

async function changeLabel(){
    if(nightSwitch === "Evening"){
        setnightSwitch("Morning");
        setmilkShift("M");
    } else {
        setnightSwitch("Evening");  
        setmilkShift("E"); 
    }
}
async function changeLabelmilkType(){
    if(MilkTypeSwitch === "Cow"){
        setMilkTypeSwitch("Buffellow");
        setmilkType("B");
    } else {
        setMilkTypeSwitch("Cow");   
        setmilkType("C");
    }
}
    return(
    <>
    <Sidebar />
        <div className="container">          
        <div className="card">          
        <div className="card-body form">
      <form autoComplete="off" onSubmit={submitForm}>
        <Row>
            <Col xs={3} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> सभासद नंबर </label>
                                <input className={` ${warnudfId ? "warning" : "form-control farmerName" }`} type="text" placeholder="सभासद नंबर"   autoComplete="off" name="udfId" value={inputs.udfId || ''} onChange={inputEvent}  />

                                {/* <select className="form-control farmerName" aria-label=" Select Sabhasad Number"   name="udfId" value={udfId || ''} onChange={onChangeudfId} >
                                           <option value="0"> सभासद नंबर निवडा  </option>                                          
                                                {prevudfId.map((x, i) =>
                                                <option key={x} value={x} > {x} </option>
                                                )}
                                </select> */}
                                </div>                         
                    </div>
            </Col>
            <Col xs={7} md={7}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> सभासदाचे नाव </label>
                                <input className={` ${warnfarmerName ? "warning" : "form-control milkWeight" }`} type="text" placeholder="सभासदाचे नाव"   autoComplete="off" name="farmerName" value={inputs.farmerName || ''} onChange={inputEvent}  readOnly={true} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Farmer Name.</p>
                            </div>                         
                    </div>
            </Col> 
            <Col xs={2} md={2}>
            <Form.Check 
                type="switch"
                label={nightSwitch}
                onClick={changeLabel}
            />
             <Form.Check 
                type="switch"
                label={MilkTypeSwitch}
                onClick={changeLabelmilkType}
            />
            </Col>          
        </Row> 
        <Row>
        <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Milk Weight </label>
                                <input className={` ${warnmilkWeight ? "warning" : "form-control milkFAT" }`} type="text" maxLength="3" placeholder="Milk Weight "   autoComplete="off" name="milkWeight" value={inputs.milkWeight || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Milk Weight.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Milk FAT </label>
                                <input className={` ${warnmilkFAT ? "warning" : "form-control milkSNF" }`} type="text" maxLength="3" placeholder="Enter Milk FAT"   autoComplete="off" name="milkFAT" value={inputs.milkFAT || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter FAT.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Milk SNF</label>
                                <input className={` ${warnmilkSNF ? "warning" : "form-control milkDegree" }`} type="text" maxLength="3" placeholder="Enter Milk SNF"  autoComplete="off" name="milkSNF" value={inputs.milkSNF || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a SNF.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Milk Degree</label>
                                <input className={` ${warnmilkDegree ? "warning" : "form-control milkWater" }`} type="text" maxLength="4" placeholder="Enter Degree" autoComplete="off" name="milkDegree" value={inputs.milkDegree || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Degree.</p>
                            </div>
                    </div>
            </Col> 
        </Row> 
        <Row>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Milk Rate  </label>
                                <input className={` ${warnmilkRate ? "warning" : "form-control" }`} type="text" maxLength="3" placeholder="Milk Rate" autoComplete="off" name="milkRate" value={inputs.milkRate || ''} onChange={inputEvent}  readOnly={true} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i> Milk Rate </p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Milk Amount</label>
                                <input className={` ${warnmilkAmount ? "warning" : "form-control" }`} type="text" placeholder="0.00" autoComplete="off" name="milkAmount" value={inputs.milkAmount || ''} onChange={inputEvent}   readOnly={true}/>
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Milk Amount</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Water in Milk</label>
                                <input className={` ${warnmilkWater ? "warning" : "form-control" }`} type="text" placeholder="0.00" autoComplete="off" name="milkWater" value={inputs.milkWater || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Collection Date </label>
                                <input className={` ${warncollectionDate ? "warning" : "form-control" }`} type="date" placeholder="Enter Date"  name="collectionDate" value={inputs.collectionDate} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Enter Date</p>
                            </div>
                    </div>
            </Col>  
             
        </Row>        
            <div>
                <button type="submit" className="btn btn-primary">Add New Collection </button>
            </div>
        {Message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {Message}
                        </div>
                        </div>
                    )}         
        </form> 
        </div>    
        </div>
        </div>



    </>
    );
}
export default CollectionEntry;