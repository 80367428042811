import React from "react";
import './signin.css';
import Laxmee from '../assets/images/laxmee.jpg';
import { Link } from "react-router-dom";

function HomePage() {

    return(
    <>
        <div className="signincontainer" style={{
            'minHeight':'100vh',
            'display' : 'flex',
            'alignItems' : 'center',
            'justifyContent' : 'center'
    }}>
            <div className="card">
                <div className="form">
                    <div className="left-side">
                     <img  src={Laxmee} alt="Logo" width="100%"/>
                    </div>

                    <div className="right-side">
                        <div className="hello">
                            <h4>Welcome to Laxmee milk</h4>
                        </div>

Welocme to The LAXMI MILK,

A competitive, innovative and sustainable dairy industry in the Laxmi Milk Dairy region that is fulfilling to all its stakeholders
To make available quality milk and other premier dairy products to urban consumers.
To abridge the gap between price of milk procurement and sale price.

<div className="register">
                        <p> Alredy member? <a href="signin">Login here</a></p>

                            <p>Not a member?  <Link to="/signup" >Register Now</Link> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </>
    );
}
export default HomePage;