import React from "react";
import Sidebar from '../sidebar/sidebar';

function DataSafety() {
    return(
        <>
        <Sidebar />
         <div className="container">
        <div className="content">
        <div> Welcome to Laxmee Doodh Milk Collection System </div>
        
        <p>
        0.1. What personal information we have about you. If you make this request, we will return to you:

0.0.1. The categories of personal information we have collected about you.

0.0.2. The categories of sources from which we collect your personal information.

0.0.3. The business or commercial purpose for collecting or selling your personal information.

0.0.4. The categories of third parties with whom we share personal information.

0.0.5. The specific pieces of personal information we have collected about you.

0.0.6. A list of categories of personal information that we have sold, along with the category of any other company we sold it to. If we have not sold your personal information, we will inform you of that fact.

0.0.7. A list of categories of personal information that we have disclosed for a business purpose, along with the category of any other company we shared it with.

Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.

0.2. To delete your personal information. If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, deletion may be accomplished through de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate.

0.3. To stop selling your personal information. We don't sell or rent your personal information to any third parties for any purpose. We do not sell your personal information for monetary consideration. However, under some circumstances, a transfer of personal information to a third party, or within our family of companies, without monetary consideration may be considered a “sale” under California law. You are the only owner of your Personal Data and can request disclosure or deletion at any time.</p>
                   
        </div>
        </div>
      </>
    );
}
export default DataSafety;