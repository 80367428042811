import React, { useState, useEffect } from "react";  
import { Container, Row, Col, Form } from "react-bootstrap";
import { read, utils, writeFile } from 'xlsx';
import Sidebar from "../sidebar/sidebar";
import axios from "axios";



 
const baseURL = process.env.REACT_APP_BASE_URL;

const token = `Bearer ` + localStorage.getItem('token');
const userRole = localStorage.getItem('userRole');
// const dairyId = localStorage.getItem('dairyId');
         

const config = {
  headers: { 'Authorization': token,       
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
   }
};

const GenerateRateChart = () => {
   
    useEffect(() => {
        getDairyData();
      }, []);

    const [rates, setRates] = useState([]);
    const [ratesToSend, setratesToSend] = useState([]);
    // const [fat, setFat] = useState([]);
    // const [col1, setCol1] = useState([]);
    // const [col2, setCol2] = useState([]);
    // const [RatechartValue, setRatechartValue] = useState([]);
    const [dairyId, setdairyId] = useState(localStorage.getItem('dairyId'));
    const [milkType, setmilkType] = useState("C");
    const [collectionShift, setcollectionShift] = useState("M");
    const [dairyData, setdairyData] = useState([]);
    // const [rateCharts, setrateCharts] = useState([]);
    const createdBy = localStorage.getItem('userId');
      const getDairyData = async () => {
        await axios.get(baseURL +"dairyregisters/", config).then(response => {
        //  this.setState( { dairyData : response.data.message});
        setdairyData(response.data.message);
         });
         }

         const onChangeDiaryId = async (e) => {
           await setdairyId(e.target.value);
         }
         const onChangecollectionShift = async(e) =>{
            await setcollectionShift(e.target.value);
         }
         const onChangemilkType = async (e) => {
            await setmilkType(e.target.value);
         }

    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    setRates(rows)
                    const arrWithColor = rows.map(object => {
                    return Object.assign(object, {milkType: milkType, dairyId: dairyId, collectionShift : collectionShift, activeStatus : "1", createdBy :  createdBy, validUpTo : "2030-12-31"});
                    });
                    setratesToSend(arrWithColor);
                }
            }
            reader.readAsArrayBuffer(file);

        }
    }
   
    

    const submitData = () => {

        axios.post(baseURL + "ratecharts/updateratestatus/", {
        dairyId : dairyId,
        milkType : milkType,
        collectionShift : collectionShift,
        updatedBy : localStorage.getItem('userId'),
        } ,config)
    
        axios
        .post(baseURL + "ratecharts/rateentry",  ratesToSend, config)
        .then(data =>{
              if(data.data.statuscode === "200"){        
                window.location.href = '/ratechart';
            } 
          })
        .catch(error => console.log(error));   
        
    }
    const handleExport = () => {
        const headings = [[
            'fat',
            'S70',
            'S71',
            'S72',
            'S73' ,
            'S74' ,
            'S75' ,
            'S76' ,
            'S77' ,
            'S78' ,
            'S79' ,
            'S80' ,
            'S81' ,
            'S82' ,
            'S83' ,
            'S84' ,
            'S85' ,
            'S86' ,
            'S87' ,
            'S88' ,
            'S89' ,
            'S90' ,
            'S91' ,
            'S92' ,
            'S93' ,
            'S94' ,
            'S95' ,
            'S96' ,
            'S97' ,
            'S98' ,
            'S99' ,
            'S100' ,
            'S101' ,
            'S102' ,
            'S103' ,
            'S104' ,
            'S105' ,
            'S106' ,
            'S107' ,
            'S108' ,
            'S109' ,
            'S110' ,
            'S111' ,
            'S112' ,
            'S113' ,
            'S114' ,
            'S115' ,
            'S116' ,
            'S117' ,
            'S118' ,
            'S119' ,
            'S120'             
        ]];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, rates, { origin: 'A3', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Chart');
        writeFile(wb, 'Rate Chart.xlsx');
    }

    return (
        <>
        <Sidebar />
        <Container>
            
        <Row>
                <Col>
                <div className="right-side">
                      <div className="input_text">
                      <Form.Select aria-label="Dairy Name Select" name="dairyId" value={dairyId} onChange={onChangeDiaryId} 
                      disabled={userRole === "User" ? true : false}
                     >
                        <option value=""> Please Select Dairy  </option>
                      { dairyData.map((e, key) => {  
                              return <option key={key} value={e.id} >{e.dairyName}</option>;  
                              })} 
                    </Form.Select>
                    </div>                         
                    </div>
                </Col>
                <Col>
                <div className="right-side">
                      <div className="input_text">
                      <Form.Select aria-label="Select Milk" name="milkType" value={milkType} onChange={onChangemilkType} 
                      >
                        <option value="C"> Cow Milk </option>
                        <option value="B"> Buffalow Milk </option>
                    </Form.Select>
                    </div>                         
                    </div>
                </Col>
                <Col>
                <div className="right-side">
                      <div className="input_text">
                      <Form.Select aria-label="Select Collection Shift" name="collectionShift" value={collectionShift} onChange={onChangecollectionShift} 
                      >
                        <option value="M"> Morning </option>
                        <option value="E"> Evening </option>
                    </Form.Select>
                    </div>                         
                    </div>
                </Col>
              </Row>
<Row>
    <Col>
    <div className="row mb-2 mt-5">
                <div className="col-sm-6">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                    <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <button onClick={handleExport} className="btn btn-primary float-right">
                                Export <i className="fa fa-download"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <table className="table generateratechart col-sm-10">
                        <thead >
                            <tr>
                                <th scope="col">Sr No</th>
                                <th scope="col">fat</th>
                                <th scope="col">S70</th>
                                <th scope="col">S71</th>
                                <th scope="col">S72</th>
                                <th scope="col">S73</th>
                                <th scope="col">S74</th>
                                <th scope="col">S75</th>
                                <th scope="col">S76</th>
                                <th scope="col">S77</th>
                                <th scope="col">S78</th>
                                <th scope="col">S79</th>
                                <th scope="col">S80</th>
                                <th scope="col">S81</th>
                                <th scope="col">S82</th>
                                <th scope="col">S83</th>
                                <th scope="col">S84</th>
                                <th scope="col">S85</th>
                                <th scope="col">S86</th>
                                <th scope="col">S87</th>
                                <th scope="col">S88</th>
                                <th scope="col">S89</th>
                                <th scope="col">S90</th>
                                <th scope="col">S91</th>
                                <th scope="col">S92</th>
                                <th scope="col">S93</th>
                                <th scope="col">S94</th>
                                <th scope="col">S95</th>
                                <th scope="col">S96</th>
                                <th scope="col">S97</th>
                                <th scope="col">S98</th>
                                <th scope="col">S99</th>
                                <th scope="col">S100</th>
                                <th scope="col">S101</th>
                                <th scope="col">S102</th>
                                <th scope="col">S103</th>
                                <th scope="col">S104</th>
                                <th scope="col">S105</th>
                                <th scope="col">S106</th>
                                <th scope="col">S107</th>
                                <th scope="col">S108</th>
                                <th scope="col">S109</th>
                                <th scope="col">S110</th>
                                <th scope="col">S111</th>
                                <th scope="col">S112</th>
                                <th scope="col">S113</th>
                                <th scope="col">S114</th>
                                <th scope="col">S115</th>
                                <th scope="col">S116</th>
                                <th scope="col">S117</th>
                                <th scope="col">S118</th>
                                <th scope="col">S119</th>
                                <th scope="col">S120</th>
                            </tr>
                        </thead>
                        <tbody> 
                                {
                                    
                                   
                                    rates.length
                                    ?
                                    rates.map((rate, index) => (
                                       <tr key={index}>
                                            <td>{ index + 1 }</td>
                                            <td > { rate.fat.toFixed(1) }</td>
                                            <td >{ (rate.S70).toFixed(2) }</td>
                                            <td >{ (rate.S71).toFixed(2) }</td>
                                            <td >{ (rate.S72).toFixed(2) }</td>
                                            <td >{ (rate.S73).toFixed(2) }</td>
                                            <td >{ (rate.S74).toFixed(2) }</td>
                                            <td >{ (rate.S75).toFixed(2) }</td>
                                            <td >{ (rate.S76).toFixed(2) }</td>
                                            <td >{ (rate.S77).toFixed(2) }</td>
                                            <td >{ (rate.S78).toFixed(2) }</td>
                                            <td >{ (rate.S79).toFixed(2) }</td>
                                            <td >{ (rate.S80).toFixed(2) }</td>
                                            <td >{ (rate.S81).toFixed(2) }</td>
                                            <td >{ (rate.S82).toFixed(2) }</td>
                                            <td >{ (rate.S83).toFixed(2) }</td>
                                            <td >{ (rate.S84).toFixed(2) }</td>
                                            <td >{ (rate.S85).toFixed(2) }</td>
                                            <td >{ (rate.S86).toFixed(2) }</td>
                                            <td >{ (rate.S87).toFixed(2) }</td>
                                            <td >{ (rate.S88).toFixed(2) }</td>
                                            <td >{ (rate.S89).toFixed(2) }</td>
                                            <td >{ (rate.S90).toFixed(2) }</td>
                                            <td >{ (rate.S91).toFixed(2) }</td>
                                            <td >{ (rate.S92).toFixed(2) }</td>
                                            <td >{ (rate.S93).toFixed(2) }</td>
                                            <td >{ (rate.S94).toFixed(2) }</td>
                                            <td >{ (rate.S95).toFixed(2) }</td>
                                            <td >{ (rate.S96).toFixed(2) }</td>
                                            <td >{ (rate.S97).toFixed(2) }</td>
                                            <td >{ (rate.S98).toFixed(2) }</td>
                                            <td >{ (rate.S99).toFixed(2) }</td>
                                            <td >{ (rate.S100).toFixed(2) }</td>
                                            <td >{ (rate.S101).toFixed(2) }</td>
                                            <td >{ (rate.S102).toFixed(2) }</td>
                                            <td >{ (rate.S103).toFixed(2) }</td>
                                            <td >{ (rate.S104).toFixed(2) }</td>
                                            <td >{ (rate.S105).toFixed(2) }</td>
                                            <td >{ (rate.S106).toFixed(2) }</td>
                                            <td >{ (rate.S107).toFixed(2) }</td>
                                            <td >{ (rate.S108).toFixed(2) }</td>
                                            <td >{ (rate.S109).toFixed(2) }</td>
                                            <td >{ (rate.S110).toFixed(2) }</td>
                                            <td >{ (rate.S111).toFixed(2) }</td>
                                            <td >{ (rate.S112).toFixed(2) }</td>
                                            <td >{ (rate.S113).toFixed(2) }</td>
                                            <td >{ (rate.S114).toFixed(2) }</td>
                                            <td >{ (rate.S115).toFixed(2) }</td>
                                            <td >{ (rate.S116).toFixed(2) }</td>
                                            <td >{ (rate.S117).toFixed(2) }</td>
                                            <td >{ (rate.S118).toFixed(2) }</td>
                                            <td >{ (rate.S119).toFixed(2) }</td>                                            
                                            <td >{ (rate.S120).toFixed(2) }</td>
                                        </tr> 
                                    ))
                                    :
                                    <tr>
                                        <td colSpan="54" className="text-center">No Rates Found.</td>
                                    </tr> 
                                   
                                }

                        </tbody>
                    </table>
                </div>
            </div>  
                     
    </Col>
</Row>
<Row>
<button type="submit" onClick={submitData} className="btn btn-primary">Update Rate Chart</button>
</Row>

        </Container>
           
        </>

    );
}

export default GenerateRateChart;
