import React, {Component} from 'react';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import Sidebar from '../sidebar/sidebar';
import axios from 'axios';

 
const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const dairyId = localStorage.getItem('dairyId');


const config = {
     headers: { Authorization: token,     
      'Access-Control-Allow-Origin' : '*',
      'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
    }   
};

export default class Feedsalelist extends Component {
    constructor(props){
        super(props);
        this.state={feedsList : [],
            hasError: false};        
    }

    async getUsersData(){
        const res = await axios.post(baseURL + "feeds/feedssaleslist" , {
          dairyId
        },config)
        this.setState({ feedsList: res.data.message });        
      }
      componentDidMount(){
        this.getUsersData()
      }
    
    render() {

        const data = () => {
            return {
                  columns: [
            {
              label: 'Sr. No.',
              field: 'id',
              width: 150,
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Sr. No',
              },
            },
            {
              label: 'Sabhasad name',
              field: 'fullName',
              width: 270,
            },
            {
              label: 'Feed Name',
              field: 'feedName',
              width: 200,
            },
            {
              label: 'Feed Rate',
              field: 'feedRate',
              width: 200,
            },{
              label: 'Quantity',
              field: 'quantity',
              width: 200,
            },{
              label: 'Total Amount',
              field: 'totalAmount',
              width: 200,
            },{
              label: 'Paid Amount',
              field: 'paidAmount',
              width: 200,
            }
          ],
          rows: this.state.feedsList,

        }
    }

        return(
            <>
            <Sidebar />
            <div className='container'>
        
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    bordered
                    hover
                    responsive
                    entriesOptions={[10, 20, 50, 100]}
                    entries={10}
                    pagesAmount={4}
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
            </div>
            </>
        );
    }

}
