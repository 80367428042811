import React, {Component} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Sidebar from '../sidebar/sidebar';
import axios from 'axios';

 
const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const dairyId = localStorage.getItem('dairyId');
const userRole = localStorage.getItem('userRole');
const config = {
  headers: { 'Authorization': token,      
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
  } 
};


export default class AddSabhasad extends Component {
    constructor(props){
        super(props);
        this.onChangedairyName = this.onChangedairyName.bind(this);
        this.onChangechairmanName = this.onChangechairmanName.bind(this);
        this.onChangedairyAddress = this.onChangedairyAddress.bind(this);
        this.onChangedairyPhone = this.onChangedairyPhone.bind(this);
        this.onChangedairyEmail = this.onChangedairyEmail.bind(this);
        this.onChangeminFat = this.onChangeminFat.bind(this);
        this.onChangemaxFat = this.onChangemaxFat.bind(this);
        this.onChangeminSNF = this.onChangeminSNF.bind(this);
        this.onChangemaxSNF = this.onChangemaxSNF.bind(this);
        this.onChangeminDegree = this.onChangeminDegree.bind(this);
        this.onChangemaxDegree = this.onChangemaxDegree.bind(this);
        

        this.state={
          dairyDetails :[],
          dairyName : dairyId,
          chairmanName : "",
          dairyAddress: "",
          dairyPhone: "",
          dairyEmail: "",
          minFat : "1.5",
          maxFat: "12.00",
          minDegree: "25.00",
          maxDegree: "35.00",
          minSNF : "6.00",
          maxSNF : "13.00",
          createdBy :"",
          updatedBy : "",  
          Message : "",  
          dairyData : [],      
          hasError: false
          };        
          this.delta = this.delta.bind(this);
    }
        delta = () => {   
      axios.get(baseURL + "dairyregisters/"+this.state.dairyName+"/" , config)
      .then(data =>{   
        this.setState({
          dairyDetails : data.data,
          chairmanName : data.data.message.chairmanName,
          dairyAddress : data.data.message.dairyAddress,
          dairyEmail : data.data.message.dairyEmail,
          dairyPhone : data.data.message.dairyPhone,
          minFat : data.data.message.minFat,
          minSNF : data.data.message.minSNF,
          minDegree : data.data.message.minDegree,
          maxFat : data.data.message.maxFat,
          maxSNF : data.data.message.maxSNF,
          maxDegree : data.data.message.maxDegree,
        });  
      }) 
      }

      async onChangedairyAddress(e) {
     await this.setState({
        dairyAddress: e.target.value
      });
    }
    async onChangedairyPhone(e) {
      await this.setState({
        dairyPhone: e.target.value
      });
    }
    async  onChangedairyEmail(e) {
      await this.setState({
        dairyEmail: e.target.value
      });
    }
    async onChangedairyName(e) {
      await  this.setState({
        dairyName: e.target.value
      });
      this.delta();
    }
    async onChangechairmanName(e) {
      await  this.setState({
        chairmanName: e.target.value
      });
    }
    async onChangeminFat(e) {
      await this.setState({
        minFat: e.target.value
      });
    }
    async onChangemaxFat(e) {
      await  this.setState({
        maxFat: e.target.value
      });
    }
    async  onChangeminSNF(e) {
      await this.setState({
        minSNF: e.target.value
      });
    }

    async onChangemaxSNF(e) {
      await this.setState({
        maxSNF: e.target.value
      });
    }
  async onChangeminDegree(e) {
      await this.setState({
        minDegree: e.target.value
      });
    }
    async onChangemaxDegree(e) {
      await this.setState({
        maxDegree: e.target.value
      });
    }

      componentDidMount(){
        this.delta(); 
        this.getDairyData();
      }
      
      async getDairyData() {
        await axios.get(baseURL +"dairyregisters/", config).then(response => {
         this.setState( { dairyData : response.data.message});
         });
         }
    
    render() {

      const  submitForm = async (event) =>{
        event.preventDefault();
  
  // const dairyName = this.state.dairyName;
  const updatedBy = localStorage.getItem('userId');
  
  const bodyParameters = {
  // id : this.state.dairyName,
  chairmanName: this.state.chairmanName,
  dairyPhone:this.state.dairyPhone,
  dairyEmail:this.state.dairyEmail,
  dairyAddress : this.state.dairyAddress,
  minFat :this.state.minFat,
  maxFat : this.state.maxFat,
  minSNF: this.state.minSNF,
  maxSNF: this.state.maxSNF,
  minDegree : this.state.minDegree,
  maxDegree :this.state.maxDegree,
  updatedBy : updatedBy,
  
  };
  
          axios
          .put(baseURL + "dairyregisters/"+ this.state.dairyName ,  
           bodyParameters, config)
          .then(data =>{
                if(data.data.statuscode === "200"){ 
                  console.log(data.data.Message); 
              } 
            })
          .catch(error => console.log(error));
        }

        return(
            <>
            <Sidebar />
            <div className="container">          
        <div className="card">          
        <div className="card-body form">
      <form autoComplete="off" onSubmit={submitForm}>
        <Row>
        <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <Form.Select aria-label="Dairy Name Select" name="dairyName" value={this.state.dairyName} onChange={this.onChangedairyName} 
                            disabled={userRole === "User" ? true : false}
                            >
                              <option value=""> Please Select Dairy  </option>
                           { this.state.dairyData.map((e, key) => {  
                                    return <option key={key} value={e.id}  >{e.dairyName}</option>;  
                                    })} 
                          </Form.Select>
                                 </div>                         
                    </div>
            </Col>
          </Row>      
        <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label className="label"> Dairy Address</label>
                                <input className="form-control" type="text" placeholder="Enter Full Address" autoComplete="off" name="dairyAddress"  value={this.state.dairyAddress}  onChange={this.onChangedairyAddress} />
                            </div>                         
                    </div>
            </Col>  
        </Row> 
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label className="label"> Chairman Name</label>
                                <input className="form-control" type="text" placeholder="Enter Chairman Name" autoComplete="off" name="chairmanName" value={this.state.chairmanName} onChange={this.onChangechairmanName}  />
                           </div>                         
                    </div>
            </Col>  
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label className="label"> Dairy Phone Number</label>
                                <input className="form-control" type="text" placeholder="Enter Dairy Phone NUmber" autoComplete="off" name="dairyPhone" value={this.state.dairyPhone} onChange={this.onChangedairyPhone}  />
                           </div>                         
                    </div>
            </Col>  
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label className="label"> Dairy Email </label>
                                <input className="form-control" type="text" placeholder="Enter Email Address" autoComplete="off" name="dairyEmail" value={this.state.dairyEmail} onChange={this.onChangedairyEmail}  />
                            </div>                         
                    </div>
            </Col>  
        </Row> 
        <Row>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label className="label"> Min FAT</label>
                                <input className="form-control" type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="1.8" autoComplete="off" name="minFat" value={this.state.minFat} onChange={this.onChangeminFat}  />
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label className="label"> Max FAT</label>
                                <input className="form-control"  type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="13.0" autoComplete="off" name="maxFat" value={this.state.maxFat} onChange={this.onChangemaxFat}  />
                             </div>                         
                    </div>
            </Col>            
        </Row> 
        <Row>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label className="label"> Min SNF</label>
                                <input className="form-control" type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="6.00" autoComplete="off" name="minSNF" value={this.state.minSNF} onChange={this.onChangeminSNF}  />
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label className="label"> Max SNF</label>
                                <input className="form-control" type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="12.00" autoComplete="off" name="maxSNF" value={this.state.maxSNF} onChange={this.onChangemaxSNF}  />
                            </div>                         
                    </div>
            </Col>            
        </Row> 
        <Row>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label className="label"> Min Degree</label>
                                <input className="form-control" type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="Enter Minimum Degree" min="25.00" step="0.05" max="35.00" presicion={2}  autoComplete="off" name="minDegree" value={this.state.minDegree} onChange={this.onChangeminDegree}  />
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label className="label"> Max Degree</label>
                                <input className="form-control" type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="Enter Max Degree" min="25.00" step="0.05" max="35.00" presicion={2}  autoComplete="off" name="maxDegree" value={this.state.maxDegree} onChange={this.onChangemaxDegree}  />
                              </div>                         
                    </div>
            </Col>            
        </Row> 
        <Row>
          <Col xs={0} md={10}>
          
          </Col>
          <Col  xs={12} md={2}>
          <button type="submit" className="btn btn-primary">Update Settings</button>
          </Col>
        </Row>     
        </form> 
        </div>    
        </div>
        </div>
            </>
        );
    }

}
