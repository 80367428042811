import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import { Row,Col } from 'react-bootstrap';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import Sidebar from '../sidebar/sidebar';
import axios from 'axios';

 
const baseURL = process.env.REACT_APP_BASE_URL;

const token = `Bearer ` + localStorage.getItem('token');
// const userRole = localStorage.getItem('userRole');
// const dairyId = localStorage.getItem('dairyId');

const config = {
  headers: { 'Authorization': token,       
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
   }
};
// const bodyParameters = {
//   dairyId: dairyId
// };
export default class SabhasadPaymentDetails extends Component {
    constructor(props){
        super(props);
        this.state={
         paymentList : [],
          dairyId : localStorage.getItem('dairyId'),
          hasError: false};
          this.delta = this.delta.bind(this);
    }

    async getUsersData(){
        var pathname = window.location.pathname.split( '/' );
        const res = await axios.post(baseURL + "payments/listcollectionbysabhsad/" , {
          dairyId: this.state.dairyId,
          payId:pathname[3],
          uid:pathname[2],
        }, config )
            this.setState({ paymentList: res.data.message });        
      }
      componentDidMount(){
        this.getUsersData();
      }
         
         delta = async (e) => {   
          await this.setState({
            dairyId: e.target.value
          });
          this.getUsersData();
        }
    
    render() {

        const data = () => {
            return {
                  columns: [
            {
              label: 'Sr. No',
              field: 'id',
              width: 75,
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Sr. No',
              },
            },
            {
              label: 'Collection Date',
              field: 'collectionDate',
              width: 100,
            },
            {
              label: 'Shift',
              field: 'milkShift',
              width: 100,
            },
            {
              label: 'Milk Weight',
              field: 'milkWeight',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'FAT',
              field: 'milkFAT',
              sort: 'disabled',
              width: 150,
            },  
            {
                label: 'SNF',
                field: 'milkSNF',
                sort: 'disabled',
                width: 150,
              },  
              {
                label: 'Degree',
                field: 'milkDegree',
                sort: 'disabled',
                width: 150,
              },   
              {
                label: 'Rate',
                field: 'milkRate',
                sort: 'disabled',
                width: 150,
              },   
              {
                label: 'Amount',
                field: 'milkAmount',
                sort: 'disabled',
                width: 150,
              },            
          ],
          rows: this.state.paymentList,

        }
    }
            return(
            <>
            <Sidebar />
            <Container>
            <Row>
              <Col>
              
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    striped
                    bordered
                    hover
                    responsiveSm
                    scrollX
                    scrollY
                    entriesOptions={[10, 20, 25, 50, 100, 1000]}
                    entries={10}
                    pagesAmount={4}
                    maxHeight="50vh"
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
            </Col>
          </Row>
            </Container>
            </>
        );
    }

}
