import React, {useEffect} from "react";
import Sidebar from '../sidebar/sidebar';
import { Container, Row, Col } from "react-bootstrap";
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from 'axios';

function DairyRegister() {
    const{useState}=React;
    const[inputs,setinputs]=useState({
      dairyName : "",
      chairmanName : "",
      dairyAddress : "",
      dairyPhone : "",
      dairyEmail : "",
      minFat : "",
      maxFat : "",
      minSNF : "",
      maxSNF : "",
      minDegree : "",
      maxDegree : "",
      allDairyList : [],
    })
    const[warndairyName, setwarndairyName]=useState(false);
    const[warnchairmanName, setwarnchairmanName]=useState(false);
    const[warndairyAddress, setwarndairyAddress]=useState(false);
    const[warndairyPhone, setwarndairyPhone]=useState(false);
    const[warndairyEmail, setwarndairyEmail]=useState(false);
    const[warnminFat, setwarnminFat]=useState(false);
    const[warnmaxFat, setwarnmaxFat]=useState(false);
    const[warnminSNF, setwarnminSNF]=useState(false);
    const[warnmaxSNF, setwarnmaxSNF]=useState(false);
    const[warnminDegree, setwarnminDegree]=useState(false);
    const[warnmaxDegree, setwarnmaxDegree]=useState(false);
    const [allDairyList, setallDairyList] = useState("");
    const[updateMethod, setupdateMethod]=useState(false);
    const[submitButtonName, setsubmitButtonName]=useState("Create New Dairy");
    const[dairyId, setdairyId]=useState();


    const[danger,setdanger]=useState(true);
    const[Message,setMessage]=useState(false);

    const inputEvent= (event)=>{
        const name=event.target.name;
        const value=event.target.value;
        if(name === "username"){
        if(value.length>0){
        setdanger(true);
        }
        }
        setinputs((lastValue)=>{
        return{
        ...lastValue,
        [name]:value
        }
        });
        };
    const data = () => {
        return {
columns: [
        {
          label: 'Sr. No.',
          field: 'id',
          width: 50,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Sr. No.'
          }
        },
        {
          label: 'Dairy Name',
          field: 'dairyName',
          width: 200,
        },
        {
          label: 'Dairy Phone',
          field: 'dairyPhone',
          width: 200,
        },{
          label: 'Dairy Email',
          field: 'dairyEmail',
          width: 150,
        }, 
        ,{
            label: 'Dairy Chairman',
            field: 'chairmanName',
            width: 150,
          },        
      ],
      rows : allDairyList
    }
}

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

useEffect(() => {
    getDairyRegister();
  }, [])

  const getDairyRegister = async () =>{
    const res = await axios.get(baseURL + "dairyregisters/" , config)
    const array = res.data.message;
    array.map(function(e){
      e.clickEvent = () => testClickEvent( e.id);
  });
  setallDairyList(array) ;
  }

  
  const submitForm = async (event) =>{
event.preventDefault();
setMessage("");
setwarndairyAddress(false);
setwarndairyEmail(false);
setwarndairyName(false);
setwarndairyPhone(false);
setwarnmaxDegree(false);
setwarnminDegree(false);
setwarnmaxFat(false);
setwarnminFat(false);
setwarnminSNF(false);
setwarnmaxSNF(false);

    const dairyName = inputs.dairyName;
    const dairyAddress = inputs.dairyAddress ;
    const dairyPhone = inputs.dairyPhone ;
    const  dairyEmail  = inputs.dairyEmail ;
    const minDegree  =  inputs.minDegree ;
    const maxDegree  =  inputs.maxDegree ;
    const minFat  =  inputs.minFat ;
    const maxFat  =  inputs.maxFat ;
    const minSNF  =  inputs.minSNF ;
    const maxSNF  =  inputs.maxSNF ;
    const chairmanName  =  inputs.chairmanName;
    const createdBy = localStorage.getItem('userId');


if(dairyName === "" ){ setwarndairyName(true); } 
if(dairyPhone === "" ){ setwarndairyPhone(true); } 
if(dairyEmail === "" ){ setwarndairyEmail(true); } 
if(chairmanName === "" ){ setwarnchairmanName(true); } 
if( dairyName !=="" && dairyPhone !== "" && dairyEmail !== ""){
    const bodyParameters = {
        dairyName: dairyName,
        dairyAddress:dairyAddress ,
        dairyPhone:dairyPhone ,
        dairyEmail :dairyEmail ,
        dairyStatus : "0" ,
        minDegree : minDegree ,
        maxDegree : maxDegree ,
        minFat : minFat ,
        maxFat : maxFat ,
        minSNF : minSNF ,
        maxSNF : maxSNF ,
        chairmanName : chairmanName,
        createdBy : createdBy
    }

if (updateMethod) {
axios
.put(baseURL +"dairyregisters/"+ dairyId,  
 bodyParameters, config)
.then(data =>{
      if(data.data.statuscode === "200"){     
      clearAllInputs();
      getDairyRegister();
      setdairyId("");
      setupdateMethod(false);
      setsubmitButtonName("Create New Dairy");
    } 
  })
.catch(error => console.log(error));
} else {    
axios
.post(baseURL +"dairyregisters/register",  
bodyParameters, config)
.then(data =>{
    console.log("data "+ data + " statuscode "+ data.data.statuscode);
    if(data.data.statuscode === "200"){     
    clearAllInputs();
    getDairyRegister();

  } 
})
.catch(error => console.log(error));
}  
}
}  
function clearAllInputs(){
    setinputs(()=>{
      return{
      dairyName:"",
      dairyAddress:"",
      dairyPhone:"",
      dairyEmail :"",
      minDegree : "",
      maxDegree : "",
      minFat : "",
      maxFat : "",
      minSNF : "",
      maxSNF : "",
      chairmanName : "",      
      dairyStatus : "0",

      }}
      )
      setsubmitButtonName("Create Dairy Register ");
    }
  
  function testClickEvent(param) {
    // clearAllInputs();
    axios.get(baseURL + "dairyregisters/" + param , config).then(
      data =>{
      setinputs(()=>{
        return{
        dairyName: data.data.message.dairyName,
        dairyPhone: data.data.message.dairyPhone,
        dairyEmail: data.data.message.dairyEmail,
        dairyAddress : data.data.message.dairyAddress,
        chairmanName : data.data.message.chairmanName,
        minFat: data.data.message.minFat,
        maxFat: data.data.message.maxFat,
        minSNF: data.data.message.minSNF,
        maxSNF: data.data.message.maxSNF,
        minDegree: data.data.message.minDegree,
        maxDegree: data.data.message.maxDegree,
        }
        });
        setdairyId( data.data.message.id);
        setupdateMethod(true);
        setsubmitButtonName("Update Dairy Register ");
  
    })
    .catch();  
  }
  
    
    return(
        <>
        <Sidebar />
        <Container>
            <Row>
                <Col xs={12} md={5}>
                <h4 className="text-center"> Dairy List</h4> 
                <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    striped
                    bordered
                    hover
                    responsiveSm
                    scrollX
                    scrollY
                    entriesOptions={[10, 20, 25, 50, 100, 1000]}
                    entries={10}
                    pagesAmount={4}
                    maxHeight="50vh"
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
                </Col>
                <Col xs={12} md={7}>
                <h4 className="text-center"> {submitButtonName }</h4>
         <form autoComplete="off" onSubmit={submitForm}>
            <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Dairy Name </label>
                                <input className={` ${warndairyName ? "warning" : "form-control" }`} type="text" placeholder="Enter Dairy Name " autoComplete="off" name="dairyName" value={inputs.dairyName ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Dairy Name.</p>
                            </div>                         
                    </div>
            </Col>
            
            </Row>
            <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Dairy Address </label>
                                <input className={` ${warndairyAddress ? "warning" : "form-control" }`} type="text" placeholder="Enter Dairy Address " autoComplete="off" name="dairyAddress" value={inputs.dairyAddress ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Dairy Address.</p>
                            </div>                         
                    </div>
            </Col>
            
            </Row>
            <Row>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Dairy Phone </label>
                                <input className={` ${warndairyPhone ? "warning" : "form-control" }`} type="text" placeholder="Enter Phone Number " autoComplete="off" name="dairyPhone" value={inputs.dairyPhone ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Dairy Phone </p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Dairy Email </label>
                                <input className={` ${warndairyEmail ? "warning" : "form-control" }`} type="text" placeholder="Enter Email " autoComplete="off" name="dairyEmail" value={inputs.dairyEmail ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Email Address</p>
                            </div>                         
                    </div>
            </Col>
            </Row>
            <Row>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Min FAT </label>
                                <input className={` ${warnminFat ? "warning" : "form-control" }`} type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="Enter Min. FAT " autoComplete="off" name="minFat" value={inputs.minFat ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Customer Code.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Max FAT </label>
                                <input className={` ${warnmaxFat ? "warning" : "form-control" }`} type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="Enter Max FAT " autoComplete="off" name="maxFat" value={inputs.maxFat ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Customer Code.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Min SNF </label>
                                <input className={` ${warnminSNF ? "warning" : "form-control" }`} type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="Enter Min. SNF " autoComplete="off" name="minSNF" value={inputs.minSNF ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Min SNF.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Max SNF </label>
                                <input className={` ${warnmaxSNF ? "warning" : "form-control" }`} type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="Enter Max SNF " autoComplete="off" name="maxSNF" value={inputs.maxSNF ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Max SNF.</p>
                            </div>                         
                    </div>
            </Col>
            </Row>
            <Row>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Min Degree </label>
                                <input className={` ${warnminDegree ? "warning" : "form-control" }`} type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="Enter Min. Degree " autoComplete="off" name="minDegree" value={inputs.minDegree ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Min Degree.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={3}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Max Degree </label>
                                <input className={` ${warnmaxDegree ? "warning" : "form-control" }`} type="number" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="Enter Max Degree " autoComplete="off" name="maxDegree" value={inputs.maxDegree ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Max Degree.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Chairman Name </label>
                                <input className={` ${warnchairmanName ? "warning" : "form-control" }`} type="text" placeholder="Enter Chairman Name " autoComplete="off" name="chairmanName" value={inputs.chairmanName ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Customer Code.</p>
                            </div>                         
                    </div>
            </Col>
            </Row>
            
        <Row>
            <Col md={6} xs={0}>
            </Col>
            <Col md={3} xs={6}>
            <button type="button" className="btn btn-warning" onClick={clearAllInputs}> Clear </button>
            </Col>
            <Col md={3} xs={6}>
            <button type="submit" className="btn btn-primary">{submitButtonName}</button>                
            </Col>
        </Row>
        {Message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {Message}
                    </div>
                    </div>
        )}

            </form>
                </Col>
            </Row>
            </Container>
      </>
    );
}
export default DairyRegister;