import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Sidebar from '../sidebar/sidebar'; 
import axios from "axios";


function SabhasadAdd() {
const{useState}=React;

const[inputs,setinputs]=useState({
  firstName: "",
  lastName: "",
  phoneNumber: "",
  whatsAppNumber: "",
  mobileNumber: "",
  fullAddress : "",
  accountNumber : "",
  accountType : "Saving",
  bankName : "",
  ifscCode : "",
  upiId : "",
  dairyId : "",
  createdBy :"",
  
Message : "",

});
// const navigate = useNavigate();

const[warnfirstName,setwarnfirstName]=useState(false);
const[warnlastName, setwarnlastName]=useState(false);
const[warnphoneNumber,setwarnphoneNumber]=useState(false);
const[warnwhatsAppNumber,setwarnwhatsAppNumber]=useState(false);
const[warnmobileNumber,setwarnmobileNumber]=useState(false);
const[warnfullAddress,setwarnfullAddress]=useState(false);
const[warnaccountNumber,setwarnaccountNumber]=useState(false);
const[warnifscCode,setwarnifscCode]=useState(false);
const[warnupiId,setwarnupiId]=useState(false);
const[warnaccountType,setwarnaccountType]=useState(false);
const[warnbankName, setwarnbankName]=useState(false);
const [prevudfId, setprevudfId] = useState([]);
const [udfId, setudfId] = useState("");

const[danger,setdanger]=useState(true);
const[Message,setMessage]=useState(false);

const navigate = useNavigate();
const inputEvent= (event)=>{
const name=event.target.name;
const value=event.target.value;
if(name === "mobileNumber"){
setinputs((lastValue)=>{
        return{
        ...lastValue,
        phoneNumber:value,
        whatsAppNumber :value
        }
        })
}
setinputs((lastValue)=>{
return{
...lastValue,
[name]:value
}
});
};

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');

const dairyId = localStorage.getItem('dairyId');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

useEffect(() => {    
        async function fetchMyAPI() {
                await  axios.post(baseURL + "sabhasads/sabhasadidbydairy",
                {
                        dairyId: dairyId
                }, config)
                .then(data => {
                        const newArr = [];
                        if (data.data.statuscode === "200") {
                                const arr = data.data.message;
                                Object.keys(arr).forEach(function (key) {
                                        newArr.push(parseInt(arr[key]['udfId']));
                                }
                                );
                                var array1 = [];
                                array1 = [...Array(200)].map((x, i) => i + 1);
                                array1 = array1.filter(function (val) {
                                        return newArr.indexOf(val) === -1;
                                });
                                setprevudfId(array1);
                                setudfId(array1[0]);
                        }
                })
                .catch(error => console.log(error));
        }
        fetchMyAPI();
}, []);

// const udfId = inputs.udfId;
const firstName = inputs.firstName;
const lastName = inputs.lastName;
const mobileNumber = inputs.mobileNumber;     
const whatsAppNumber = inputs.whatsAppNumber;
const phoneNumber = inputs.phoneNumber;   
const fullAddress = inputs.fullAddress;
const bankName = inputs.bankName;
const accountNumber = inputs.accountNumber;
const accountType = inputs.accountType;        
const ifscCode = inputs.ifscCode;
const upiId = inputs.upiId;
const activeStatus = "0";        
const createdBy = localStorage.getItem('userId');

const bodyParameters = {
dairyId: dairyId,
udfId : udfId,
firstName:firstName,
lastName:lastName,
mobileNumber:mobileNumber,
whatsAppNumber:whatsAppNumber,
phoneNumber:phoneNumber,
fullAddress :fullAddress,
bankName :bankName,
accountNumber:accountNumber,
accountType : accountType,
ifscCode : ifscCode,
upiId :upiId,
activeStatus: activeStatus,
createdBy : createdBy,

};


const submitForm = async (event) =>{
    event.preventDefault();
setMessage("");
setwarnfirstName(false);
setwarnlastName(false);
setwarnphoneNumber(false);
setwarnmobileNumber(false);
setwarnwhatsAppNumber(false);
setwarnaccountNumber(false);
setwarnaccountType(false);
setwarnbankName(false);
setwarnfullAddress(false);
setwarnifscCode(false);
setwarnupiId(false);
if(firstName === "" ){ setwarnfirstName(true); } 
if(whatsAppNumber === "" ){ setwarnwhatsAppNumber(true); } 
if(phoneNumber === "" ){ setwarnphoneNumber(true); } 
if(mobileNumber === "" ){ setwarnmobileNumber(true); } 
if( udfId !=="" && firstName !== "" && whatsAppNumber !== "" && mobileNumber !=="" && phoneNumber !==""){
  axios
  .post(baseURL +"sabhasads/register",  
   bodyParameters, config)
  .then(data =>{
        if(data.data.statuscode === "200"){        
      navigate("/sabhasadlist");
      } 
    })
  .catch(error => console.log(error));
  }
}  
async function onChangeudfId(e) {
        setudfId(e.target.value);
}    
    return(
    <>
    <Sidebar />
        <div className="container">          
        <div className="card">          
        <div className="card-body form">
      <form autoComplete="off" onSubmit={submitForm}>
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> सभासद नंबर </label>
                                <select className="form-control" aria-label=" Select Sabhasad Number " name="udfId" value={udfId || ''} onChange={onChangeudfId} >
                                           <option value="0"> सभासद नंबर निवडा  </option>                                          
                                                {prevudfId.map((x, i) =>
                                                <option key={x} value={x} > {x} </option>
                                                )}
                                </select>
                                </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> सभासदाचे नाव </label>
                                <input className={` ${warnfirstName ? "warning" : "form-control" }`} type="text" placeholder="सभासदाचे नाव" autoComplete="off" name="firstName" value={inputs.firstName || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> सभासदाचे आडनाव  </label>
                                <input className={` ${warnlastName ? "warning" : "form-control" }`} type="text" placeholder="सभासदाचे आडनाव " autoComplete="off" name="lastName" value={inputs.lastName || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col> 
        </Row> 
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Mobile Number </label>
                                <input className={` ${warnmobileNumber ? "warning" : "form-control" }`} type="text" placeholder="Enter Mobile Number" autoComplete="off" name="mobileNumber" value={inputs.mobileNumber || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Mobile.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Phone Number</label>
                                <input className={` ${warnphoneNumber ? "warning" : "form-control" }`} type="text" placeholder="Enter Phone Number" autoComplete="off" name="phoneNumber" value={inputs.phoneNumber || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> WhatsApp Number</label>
                                <input className={` ${warnwhatsAppNumber ? "warning" : "form-control" }`} type="text" placeholder="Enter WhatsApp Number" autoComplete="off" name="whatsAppNumber" value={inputs.whatsAppNumber || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col> 
        </Row> 
        <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> पूर्ण पत्ता  </label>
                                <input className={` ${warnfullAddress ? "warning" : "form-control" }`} type="text" placeholder="पूर्ण पत्ता " autoComplete="off" name="fullAddress" value={inputs.fullAddress || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i> पूर्ण पत्ता </p>
                            </div>                         
                    </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Bank Name</label>
                                <input className={` ${warnbankName ? "warning" : "form-control" }`} type="text" placeholder="Enter Bank Name" autoComplete="off" name="bankName" value={inputs.bankName || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Mobile.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Account Number </label>
                                <input className={` ${warnaccountNumber ? "warning" : "form-control" }`} type="text" placeholder="Enter Account Number" autoComplete="off" name="accountNumber" value={inputs.accountNumber || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> IFSC Code </label>
                                <input className={` ${warnifscCode ? "warning" : "form-control" }`} type="text" placeholder="Enter IFSC Code" autoComplete="off" name="ifscCode" value={inputs.ifscCode || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a IFSC Code</p>
                            </div>
                    </div>
            </Col>  
             
        </Row>
        <Row>
        <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Account Type</label>
                                <input className={` ${warnaccountType ? "warning" : "form-control" }`} type="text" placeholder="Enter Account Type" autoComplete="off" name="accountType" value={inputs.accountType || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> UPI Id </label>
                                <input className={` ${warnupiId ? "warning" : "form-control" }`} type="text" placeholder="Enter UPI Id" autoComplete="off" name="upiId" value={inputs.upiId || ''} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Mobile.</p>
                            </div>                         
                    </div>
            </Col>
                       
        </Row>
            <div>
                <button type="submit" className="btn btn-primary">Add New Sabhasad </button>
            </div>
        {Message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {Message}
                        </div>
                        </div>
                    )}         
        </form> 
        </div>    
        </div>
        </div>



    </>
    );
}
export default SabhasadAdd;