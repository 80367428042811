import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import { Row,Col } from 'react-bootstrap';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import Sidebar from '../sidebar/sidebar';
import PaymentPrintPage from './paymentprintpage';
import { Link } from "react-router-dom";
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const token = `Bearer ` + localStorage.getItem('token');
// const userRole = localStorage.getItem('userRole');

// const dairyId = localStorage.getItem('dairyId');
function testClickEvent(param, payId) {
  window.location.href = '/sabhasadpaymentdetails/'+param +'/'+payId;
}

const config = {
  headers: { 'Authorization': token,       
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
   }
};
// const bodyParameters = {
//   dairyId: dairyId
// };
export default class PaymentDetails extends Component {
    constructor(props){
        super(props);      
        var pathname = window.location.pathname.split( '/' );  
        // console.log(this.props.match.params.id);
        // payId = window.location.pathname;
        this.state={
         paymentList : [],
          dairyData : [],
          payId : pathname[2], 
          dairyId : localStorage.getItem('dairyId'),
          hasError: false};
          this.delta = this.delta.bind(this);
    }

    async getUsersData(){
        var pathname = window.location.pathname.split( '/' );
        const res = await axios.post(baseURL + "payments/listalldetails", {
          dairyId: this.state.dairyId,
          payId : pathname[2],
        }, config )
        const array = res.data.message;
           array.map(function(e){
        return e.clickEvent = () => testClickEvent( e.uid, e.payId);
            });
            this.setState({ paymentList: array });        
      }
      componentDidMount(){
        this.getUsersData();
        // this.getDairyData() ;
      }         
         delta = async (e) => {  
          this.getUsersData();
        }
    
    render() {

        const data = () => {
            return {
                  columns: [
            {
              label: 'Sr. No',
              field: 'id',
              width: 75,
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Sr. No',
                'className' : 'text-end'
              },
            },
            {
              label: 'Payment Date',
              field: 'payDate',
              width: 100,
            },
            {
              label: 'Farmer ',
              field: 'firstName',
              width: 100,
            },
            {
                label: 'Name',
                field: 'lastName',
                width: 100,
              },
            {
              label: 'Total Milk',
              field: 'totalWeight',
              sort: 'asc',
              width: 100,
            },
            {
                label: 'SNF',
                field: 'avgSNF',
                sort: 'disabled',
                width: 150,
              }, 
              {
              label: 'FAT',
              field: 'avgFAT',
              sort: 'disabled',
              width: 150,
            },   
            {
            label: 'Degree',
            field: 'avgDegree',
            sort: 'disabled',
            width: 150,
          }, 
            {
              label: 'Average Rate',
              field: 'avgRate',
              sort: 'disabled',
              width: 150,
            },  
            {
                label: 'Sub Total',
                field: 'subTotal',
                sort: 'disabled',
                width: 150,
              },  
              {
                label: 'Payable Payment',
                field: 'payableAmount',
                sort: 'disabled',
                width: 150,
              },            
          ],
          rows: this.state.paymentList,

        }
    }
            return(
            <>
            <Sidebar />
            <Container>
            <Row>
                <Col xs={12} md={12}>
                <button className='btn btn-success'><Link to={`/paymentprint/${ this.state.payId }`}  > Download PDF </Link></button>
                </Col>
              <Col>
              
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    striped
                    bordered
                    hover
                    responsiveSm
                    scrollX
                    scrollY
                    entriesOptions={[10, 20, 25, 50, 100, 1000]}
                    entries={10}
                    pagesAmount={4}
                    maxHeight="50vh"
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
            </Col>
          </Row>
            </Container>
            </>
        );
    }

}
