import React, { Component } from 'react'
import axios from 'axios'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      margin:10,
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,      
      flexDirection: 'row',
    },
    table: {
        width: '99%',
        textAlign:'center',
        fontSize:'11px',
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #CCC',
      },
      tr: {
        borderRight: '1px solid #CCC',
        fontSize:'8px',
      },
      header: {
        borderTop: '2px solid #CCC',
        fontSize:'10px',
        textAlign:'center'
      },
      bold: {
        fontWeight: 'bold',
      },
    row1: {
        width: '10%',
      },
      row2: {
        width: '15%',
      },
      row3: {
        width: '15%',
      },
      row4: {
        width: '20%',
      },
      row5: {
        width: '25%',
      },
      row6: {
        width: '35%',
      },
      textright :{
        textAlign:'right',
      },
      textleft :{
        textAlign:'left',
      }
  });

  
const baseURL = process.env.REACT_APP_BASE_URL;

const token = `Bearer ` + localStorage.getItem('token');
const userRole = localStorage.getItem('userRole');

// const dairyId = localStorage.getItem('dairyId');
const config = {
  headers: { 'Authorization': token,       
    'Access-Control-Allow-Origin' : '*',
    'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
   }
};
var pathname = window.location.pathname.split( '/' );
export default class PaymentPridData extends Component {
    
    constructor(props){
        super(props);
        this.state={
          paymentList : [],
          paymentListTotal : [],
          payId : pathname[2],
          dairyId : localStorage.getItem('dairyId'),
          hasError: false
        };
          this.delta = this.delta.bind(this);
    }
    async getUsersData(){
        const res = await axios.post(baseURL + "payments/listalldetails" , {
          dairyId: this.state.dairyId,
      payId: this.state.payId,
        }, config )
        const array = res.data.message;
            this.setState({ paymentList: array });

            const restotal = await axios.get(baseURL + "payments/"+this.state.payId, config )
              const arraytotal = restotal.data.message;
                  this.setState({ paymentListTotal: arraytotal }); 
                //   console.log("this.state.paymentListTotal" + this.state.paymentListTotal); 
      }
      componentDidMount(){
        this.getUsersData();
      }
      delta = async (e) => {  
        this.getUsersData();
      }
  render() {
    return (
      <>
        <Document>
    <Page size="A4" style={styles.table}>
      <View style={styles.table}>
        <View style={{paddingTop:'20px'}}>
            <Text> Laxmee Doodh { localStorage.getItem('dairyId')} </Text>
        </View>
      <View style={[styles.row, styles.bold, styles.header]}>
        <Text style={[styles.tr, styles.row1]}>Sr. No.</Text>
        <Text style={[styles.tr, styles.row6]}>Farmer Name</Text>
        <Text style={[styles.tr, styles.row4]}>Weight</Text>
        <Text style={[styles.tr, styles.row2]}>FAT</Text>
        <Text style={[styles.tr, styles.row2]}>SNF</Text>
        <Text style={[styles.tr, styles.row2]}>Degree</Text>
        <Text style={[styles.tr, styles.row2]}>Rate</Text>
        <Text style={[styles.tr, styles.row2]}> Deduction </Text>
        <Text style={[styles.tr, styles.row4]}>Pay Amount</Text>        
      </View>

      {this.state.paymentList.map((row, i) => (
      <View key={i} wrap={false} style={[styles.row]}>
      <Text style={[styles.tr, styles.row1]}>{i+ 1}</Text>
        <Text style={[styles.tr, styles.row6, styles.textleft]}>{row.firstName}   {row.lastName}</Text>
        <Text style={[styles.tr, styles.row4, styles.textright]}>{row.totalWeight}</Text>
        <Text style={[styles.tr, styles.row2,styles.textright]}>{row.avgFAT}</Text>
        <Text style={[styles.tr, styles.row2, styles.textright]}>{row.avgSNF}</Text>
        <Text style={[styles.tr, styles.row2, styles.textright]}>{row.avgDegree}</Text>
        <Text style={[styles.tr, styles.row2, styles.textright]}>{row.avgRate}</Text>
        <Text style={[styles.tr, styles.row2, styles.textright]}> {row.monthlyDeduction} </Text>
        <Text style={[styles.tr, styles.row4, styles.textright]}> {row.payableAmount}</Text>
      </View>
      )
    )}
    {/* {this.state.paymentListTotal.map((row1, i) => (
      <View key={i} wrap={false} style={[styles.row]}>
      <Text style={[styles.tr, styles.row1]}> </Text>
        <Text style={[styles.tr, styles.row6, styles.textleft]}> </Text>
        <Text style={[styles.tr, styles.row4, styles.textright]}>{row1.totalWeight}</Text>
        <Text style={[styles.tr, styles.row2,styles.textright]}>{row1.avgFAT}</Text>
        <Text style={[styles.tr, styles.row2, styles.textright]}>{row1.avgSNF}</Text>
        <Text style={[styles.tr, styles.row2, styles.textright]}>{row1.avgDegree}</Text>
        <Text style={[styles.tr, styles.row2, styles.textright]}>{row1.avgRate}</Text>
        <Text style={[styles.tr, styles.row2, styles.textright]}> {row1.totDeduction} </Text>
        <Text style={[styles.tr, styles.row4, styles.textright]}> {row1.totalPayment}</Text>
      </View>
      )
    )} */}

      </View>
    </Page>
  </Document>
      </>
    )
  }
}
