import React  from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import HomePage from './login/homepage';
import Signin from './login/signin';
import Signup from './login/signup';
import Dashboard from './pages/dashboard/dashboard';
import Logout from './login/logout';
// import Sabhasad from './pages/sabhasad/sabhasad';
import RateChart from "./pages/ratechart/ratechart";
import Sabhasadlist from './pages/sabhasad/sabhasadlist';
import SabhsadAdd from './pages/sabhasad/sabhasadadd';
import Feedslist from "./pages/feeds/feedslist";
import FeedAdd  from "./pages/feeds/feedadd";
import Feedsales from "./pages/feeds/feedsales";
import Feedsalelist from './pages/feeds/feedsalelist';
import DeductionAdd from "./pages/deduction/deductionadd";
import DeductionList from "./pages/deduction/deductionlist";
import Settings from "./pages/settings/settings";
import GeneratePayment from './pages/payments/generatepayment';
import FeedEdit from './pages/feeds/feededit';
import SabhasadEdit from './pages/sabhasad/sabhasadedit';
import PrivacyPolicy from './pages/dashboard/privacy';
import DataSafety from './pages/dashboard/datasafety';
import DairyRegister from './pages/register/dairyregister';
import UserRegister from './pages/register/userregister';
import GenerateRateChart from './pages/ratechart/generateratechart';
import AdvancePayment from './pages/payments/advance';
import Collections from './pages/collections/collections'; 
import Calender from './pages/calender/calender'; 
import CollectionEntry from './pages/collections/collectionentry';
import PaymentList from './pages/payments/paymentlist';
import PaymentDetails from './pages/payments/paymentdetails';
import SabhasadPaymentDetails from './pages/payments/sabhsadpaymentdetails';
import PaymentPrintPage from './pages/payments/paymentprintpage';

import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  
// const navigate = useNavigate();
//   useEffect(() => {
//     if(!localStorage.getItem("token")) {
//       navigate("/login");
//     }
//   }, [])

  if(!localStorage.getItem('token')  ) {  

    if(window.location.pathname === "/"){
      window.location.href = '/';
    } 

  if(window.location.pathname === "/privacy-policy"){
    window.location.href = '/privacy-policy';
  } if(window.location.pathname === "/calender"){
    window.location.href = '/calender';
  } 
  else if(window.location.pathname !== "/signin" ){
    window.location.href = '/signin';
  } 
  } 
  
  return (
    <>
     <BrowserRouter>
     <Routes>
     <Route path="/" element={<HomePage />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route path="/signin" element={<Signin />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/Dashboard" element={<Dashboard />} ></Route>
        <Route path="/Logout" element={<Logout />}></Route>
        {/* <Route path="/Sabhasad" element={<Sabhasad />}></Route> */}
        <Route path="/RateChart" element={<RateChart />}></Route>
        <Route path='/generateratechart' element={<GenerateRateChart />} ></Route>
        <Route path="/sabhasadlist" element={<Sabhasadlist />}></Route>
        <Route path="/addsabhasad" element={<SabhsadAdd />}></Route>
        <Route path="/sabhasadedit/:id" element={<SabhasadEdit />}></Route>
        <Route path="/feedslist" element={<Feedslist />}></Route>
        <Route path="/feedadd" element={<FeedAdd />}></Route>
        <Route path="/feededit/:id" element={<FeedEdit />}></Route>
        <Route path="/feedsales" element={<Feedsales />}></Route>
        <Route path="/feedsalelist" element={<Feedsalelist />}></Route>
        <Route path="/deduction" element={<DeductionList />}></Route>
        <Route path="/deductionadd" element={<DeductionAdd />}></Route>
        <Route path="/settings" element={<Settings />}></Route>
        <Route path="/dairyregister" element={<DairyRegister />}></Route>
        <Route path="/userregister" element={<UserRegister />}></Route>
        <Route path="/generatepayment" element={<GeneratePayment />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/data-safety" element={<DataSafety />}></Route>
        <Route path="/advance" element={<AdvancePayment />} > </Route>
        <Route path="/collections" element={<Collections />} > </Route>
        <Route path="/collectionentry" element={<CollectionEntry />} ></Route>
        <Route path="/calender" element={<Calender />} ></Route>
        <Route path="/payments" element={<PaymentList />} ></Route>
        <Route path="/paymentdetails/:id" element={<PaymentDetails />} ></Route>
        <Route path="/sabhasadpaymentdetails/:id/:any" element={<SabhasadPaymentDetails />} ></Route>
        <Route path="/paymentprint/:id" element={<PaymentPrintPage />} ></Route>

     </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
