import React from "react";
import { useNavigate } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Sidebar from '../sidebar/sidebar'; 
import axios from "axios";
import Form from 'react-bootstrap/Form';

function DeductionAdd() {
const{useState}=React;

const[inputs,setinputs]=useState({
  deductionTitle : "",
  deductionType: "",
  deductionValue : "",
  createdBy :"",
updatedBy : "",  
Message : "",

});
// const navigate = useNavigate();

const[warndeductionTitle,setwarndeductionTitle]=useState(false);
const[warndeductionType,setwarndeductionType]=useState(false);
const[warndeductionValue,setwarndeductionValue]=useState(false);

const[danger,setdanger]=useState(true);
const[Message,setMessage]=useState(false);

const navigate = useNavigate();
const inputEvent= (event)=>{
const name=event.target.name;
const value=event.target.value;
if(name === "username"){
if(value.length>0){
setdanger(true);
}
}
setinputs((lastValue)=>{
return{
...lastValue,
[name]:value
}
});
};

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');

const dairyId = localStorage.getItem('dairyId');
const config = {
  headers: {    
    'Authorization': token,    
    'Access-Control-Allow-Origin' : '*',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
   }
};

const deductionTitle = inputs.deductionTitle;
const deductionType = inputs.deductionType;
const deductionValue = inputs.deductionValue;
const createdBy = localStorage.getItem('userId');
// const updatedBy = localStorage.getItem('userId');

const bodyParameters = {
deductionTitle: deductionTitle,
deductionType : deductionType,
deductionValue : deductionValue,
dairyId  :dairyId,
createdBy : createdBy
};


const submitForm = async (event) =>{
    event.preventDefault();
setMessage("");
setwarndeductionTitle(false);
setwarndeductionType(false);
setwarndeductionValue(false);
if(deductionTitle === "" ){ setwarndeductionTitle(true); } 
if(deductionType === "" ){ setwarndeductionType(true); } 
if(deductionValue === "" ){ setwarndeductionValue(true); } 
if( deductionTitle !=="" && deductionType !== "" && deductionValue !== ""){
  axios
  .post(baseURL + "deductions/register",  
   bodyParameters, config)
  .then(data =>{
        if(data.data.statuscode === "200"){        
      navigate("/deduction");
      } 
    })
  .catch(error => console.log(error));
  }
}      
    return(
    <>
    <Sidebar />
        <div className="container">          
        <div className="card">          
        <div className="card-body form">
      <form autoComplete="off" onSubmit={submitForm}>
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <input className={` ${warndeductionTitle ? "warning" : "form-control" }`} type="text" placeholder="Enter Deduction Title" autoComplete="off" name="deductionTitle" value={inputs.deductionTitle} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Deduction Title </p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                    <div className="input_text">
                    <Form.Select  className={` ${warndeductionType ? "warning" : "form-control" }`} aria-label="Default select example" name="deductionType" onChange={inputEvent} >
                      <option value="Per Liter Deduction">Per Liter Deduction</option>
                      <option value="On Amount Deduction">On Amount Deduction</option>
                    </Form.Select>
                    </div>
                    <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Select Deduction Type</p>
                            {/* <div className="input_text">
                                <input className={` ${warndeductionType ? "warning" : "form-control" }`} type="text" placeholder="Enter Deduction Type" autoComplete="off" name="deductionType" value={inputs.deductionType} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Feed Rate</p>
                            </div>                          */}
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <input className={` ${warndeductionValue ? "warning" : "form-control" }`} type="text" placeholder="Enter Deduction Value" autoComplete="off" name="deductionValue" value={inputs.deductionValue} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Deduction Value</p>
                            </div>                         
                    </div>
            </Col>            
        </Row> 
            <div>
                <button type="submit" className="btn btn-primary">Save Deduction</button>
            </div>
        {Message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {Message}
                        </div>
                        </div>
                    )}         
        </form> 
        </div>    
        </div>
        </div>



    </>
    );
}
export default DeductionAdd;