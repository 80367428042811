import React, {useEffect} from "react";
import Sidebar from '../sidebar/sidebar';
import { Container, Row, Col, Form } from "react-bootstrap";
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from 'axios';


function AdvancePayment() {
    const{useState}=React;
    const[inputs,setinputs]=useState({
      firstName : "",
      narration : "",
      lastName : "",
      udfId : "",
      farmerName : "",
      trAmount : "",
      password : "",
      allDairyList : [],
    })
    
    

    // const [dairyName, setdairyName] = useState("");
    // const[warnfirstName, setwarnfirstName]=useState(false);
    const[warnnarration, setwarnnarration]=useState(false);
    // const[warnlastName, setwarnlastName]=useState(false);
    const[warnudfId, setwarnudfId]=useState(false);
    // const[warndairyName, setwarndairyName]=useState(false);
    // const[warndairyAddress, setwarndairyAddress]=useState(false);
    const[warntrAmount, setwarntrAmount]=useState(false);
    // const[warnpassword, setwarnpassword]=useState(false);
    const[allAdvanceList, setallAdvanceList] = useState("");
    const[updateMethod, setupdateMethod]=useState(false);
    const[submitButtonName, setsubmitButtonName]=useState("Pay Advance");
    const[dairyId, setdairyId]=useState(localStorage.getItem('dairyId'));
    const[advacenId, setadvacenId]=useState("");    
    const[farmerName, setfarmerName]=useState("");
    const[uid, setuid]=useState("");
    const[trType, settrType]=useState("0");
    const[trDate, settrDate]=useState(new Date());
    const[dairyData, setdairyData]=useState([]);
    // const [dairyAddress, setdairyAddress] = useState("");


    const[danger,setdanger]=useState(true);
    const[Message,setMessage]=useState(false);
    

    const inputEvent= async (event)=>{
        const name=event.target.name;
        const value=event.target.value;
       
        setinputs((lastValue)=>{
        return{
        ...lastValue,
        [name]:value
        }
        });
        if(name === "udfId"){
            await axios.post(baseURL +"sabhasads/sabhasad", {
              dairyId,
              udfId : event.target.value , 
            },config).then(response => {
              if(response.data.statuscode === "200"){
                setfarmerName(response.data.message.firstName + " " + response.data.message.lastName);
                setuid(response.data.message.id);
              }  
              });
        }
        };
    const data = () => {
        return {
columns: [
        {
          label: 'Sr. No.',
          field: 'id',
          width: 75,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Sr. No.'
          },
        },
        {
          label: 'Sabhasad Name',
          field: 'farmerName',
          width: 150,
        },
        {
          label: 'Advance Paid Amount',
          field: 'trAmount',
          width: 150,
        }, {
            label: 'Advance Return Amount',
            field: 'crAmount',
            width: 150,
          },{
          label: 'Pay Date',
          field: 'trDate',
          width: 250,
        }, 
        ,{
            label: 'Narration',
            field: 'narration',
            width: 150,
          },        
      ],
      rows : allAdvanceList
    }
}

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const userRole = + localStorage.getItem('userRole');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

useEffect(() => {
    getAdvanceRegister();
    getDairyData();
  }, [])

  const getAdvanceRegister = async () =>{
    const res = await axios.get(baseURL + "advances/" , config)
    const array = res.data.message;
    array.map(function(e){
      e.clickEvent = () => testClickEvent( e.id);
  });
  setallAdvanceList(array) ;
  }

  
  const submitForm = async (event) =>{
event.preventDefault();
setMessage("");
setwarnudfId(false);
setwarntrAmount(false);

    const udfId = inputs.udfId ;
    const trAmount  =  inputs.trAmount ;
    const narration  =  inputs.narration;
    const createdBy = localStorage.getItem('userId');

if(udfId === "" ){ setwarnudfId(true); } 
if(trAmount === "" ){ setwarntrAmount(true); } 
if( uid !=="" && udfId !== "" && trAmount !== ""){
   let crAmount = "0.00";
   let drAmount = "0.00";
   if(trType === "0"){
    crAmount = "0.00";
    drAmount = trAmount
   } else {
    crAmount = trAmount;
    drAmount = "0.00";
   }
    console.log(updateMethod);
if (updateMethod) {
   
axios
.put(baseURL +"advances/"+ advacenId,   
{
    uid: uid ,
    dairyId :dairyId,
    udfId:udfId ,
    trType : trType ,
    trDate : trDate,
    farmerName : farmerName ,
    trAmount : drAmount ,
    crAmount : crAmount ,
    narration : narration,
    updatedBy : createdBy
}, config)
.then(data =>{
      if(data.data.statuscode === "200"){     
      clearAllInputs();
      getAdvanceRegister();
      setdairyId("");
      setadvacenId("");
      setupdateMethod(false);
      setsubmitButtonName("Pay Advance");
    } 
  })
.catch(error => console.log(error));
} else {    
axios
.post(baseURL +"advances/register",  
{
    uid: uid,
    dairyId : dairyId,
    udfId: udfId ,
    trType : trType ,
    farmerName : farmerName ,
    trAmount : drAmount ,
    crAmount : crAmount ,
    trDate : trDate,
    narration : narration,
    createdBy : createdBy
}, config)
.then(data =>{
    if(data.data.statuscode === "200"){     
    clearAllInputs();
    getAdvanceRegister();

  } 
})
.catch(error => console.log(error));
}  
}
}  
function clearAllInputs(){
    setinputs(()=>{
      return{
      udfId:"",
      trAmount : "",
      narration : "",

      }}
      )
      setdairyId(localStorage.getItem('dairyId'));
      setfarmerName("");
      setsubmitButtonName("Pay Advance");
    }
  
  function testClickEvent(param) {
    // clearAllInputs();
    axios.get(baseURL + "advances/" + param , config).then(
      data =>{
      setinputs(()=>{
        return {
        udfId: data.data.message.udfId,
        trAmount : data.data.message.trAmount,
        narration : data.data.message.narration,
        }
        });
        setfarmerName(data.data.message.farmerName);
        setuid(data.data.message.uid);
        settrType(data.data.message.trType);
        setdairyId(data.data.message.dairyId);
        setadvacenId(data.data.message.id);
        setupdateMethod(true);
        setsubmitButtonName("Update Advance");
  
    })
    .catch();  
  }
  
  async function onChangetrType(e) {
    settrType(e.target.value);
  } 
  async function onChangedairyId(e) {
    setdairyId(e.target.value);
  } 
  async function getDairyData() {
    await axios.get(baseURL +"dairyregisters/", config).then(response => {
     setdairyData(response.data.message);
     });
     }
    return(
        <>
        <Sidebar />
        <Container>
            <Row>
                <Col xs={12} md={5}>
                <h4 className="text-center"> Advance Paid </h4> 
                <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    striped
                    bordered
                    hover
                    responsiveSm
                    scrollX
                    scrollY
                    entriesOptions={[10, 20, 25, 50, 100, 1000]}
                    entries={10}
                    pagesAmount={4}
                    maxHeight="50vh"
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
                </Col>
                <Col xs={12} md={7}>
                <h4 className="text-center"> {submitButtonName }</h4>
         <form autoComplete="off" onSubmit={submitForm}>
            <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Select Dairy </label>
                                <Form.Select aria-label=" Select Dairy " name="dairyId" onChange={onChangedairyId} value={dairyId} 
                                disabled={userRole === "User" ? true : false} >
                                <option value="" data-address=""> Please Select Dairy  </option>
                           { dairyData.map((e, key) => {  
                                    return <option key={key} value={e.id}  data-address={e.dairyAddress}>{e.dairyName}</option>;  
                                    })} 
                </Form.Select>
                </div>                         
                    </div>
            </Col>  
            </Row>           
            <Row>
            <Col xs={4} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Sabhasad Number </label>
                                <input className={` ${warnudfId ? "warning" : "form-control" }`} type="text" placeholder="Enter Sabhasad Number " autoComplete="off" name="udfId" value={inputs.udfId ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Sabhasad Number </p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={8} md={8}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label >Sabhasad Name  </label>
                                <input className="form-control" type="text"  placeholder="Farmer Name" autoComplete="off" name="farmerName" value={ farmerName ||  '' }  readOnly/>
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Farmer Name</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Amount</label>
                                <input className={` ${warntrAmount ? "warning" : "form-control" }`} type="text"  placeholder="Enter Amount" autoComplete="off" name="trAmount" value={inputs.trAmount ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Enter Amouont.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label > Transection Type </label>
                                <Form.Select aria-label=" Status Select" name="trType" value={trType} onChange={onChangetrType} >
                                <option value="0">Debit</option>
                                <option value="1">Credit</option>
                            </Form.Select>
                              </div>                         
                    </div>
            </Col>
            </Row>
            <Row>                 
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Narration </label>
                                <input className={` ${warnnarration ? "warning" : "form-control" }`} type="text" placeholder="Enter Narration" autoComplete="off" name="narration" value={inputs.narration ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Narration.</p>
                            </div>                         
                    </div>
            </Col>
            </Row>
            
        <Row>
            <Col md={6} xs={0}>
            </Col>
            <Col md={3} xs={6}>
            <button type="button" className="btn btn-warning" onClick={clearAllInputs}> Clear </button>
            </Col>
            <Col md={3} xs={6}>
            <button type="submit" className="btn btn-primary">{submitButtonName}</button>                
            </Col>
        </Row>
        {Message && (
                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {Message}
                    </div>
                    </div>
        )}

            </form>
                </Col>
            </Row>
            </Container>
      </>
    );
}
export default AdvancePayment;