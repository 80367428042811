import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";



function Sidebar() {

  const [disabledField, setdisabledField] = useState(true);
  
if(disabledField){
  if(localStorage.getItem('userRole') === "Admin") {
    setdisabledField(false);
  } 
}

  return (    
    <>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" >
      <Container>

        <Navbar.Brand ><Link to="/dashboard"><img src={process.env.PUBLIC_URL+"/laxmeemilk.jpeg"} width="100px" alt="Laxmee Milk"/></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <NavDropdown title="Sabhasad Master" id="collasible-nav-dropdown">
              <NavDropdown.Item><Link to="/sabhasadlist">Sabhasad List</Link></NavDropdown.Item>
              <NavDropdown.Item> <Link to="/addsabhasad">  New Sabhasad </Link> </NavDropdown.Item>    
              {/* <NavDropdown.Item href="/sabhasad">All List</NavDropdown.Item>           */}
            </NavDropdown>
            <NavDropdown title="Feeds" id="collasible-nav-dropdown">
            <NavDropdown.Item><Link to="/feedsales">Feeds Sales Entry </Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/feedsalelist">Feeds Sales List </Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/feedslist">Feeds List </Link></NavDropdown.Item>
              <NavDropdown.Item> <Link to="/feedadd">  New Feeds </Link> </NavDropdown.Item>    
            </NavDropdown>
            {/* <Nav.Link href="/collections">Collections</Nav.Link> */}
            <NavDropdown title="Collections" id="collasible-nav-dropdown">
            <NavDropdown.Item><Link to="/collectionentry">Collections Entry </Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/collections">Collections List </Link></NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/ratechart">Ratechart</Nav.Link>           
            <NavDropdown title="Payments" id="collasible-nav-dropdown">
              <NavDropdown.Item > <Link to="/payments"> Payments </Link></NavDropdown.Item>
              <NavDropdown.Item>
               <Link to="/generatepayment"> Generate Payment </Link>
              </NavDropdown.Item>
              <NavDropdown.Item> <Link to="/deduction">Deductions </Link></NavDropdown.Item>
              <NavDropdown.Item ><Link to="/deductionadd"> New Deductions</Link></NavDropdown.Item>
              <NavDropdown.Divider />
            <NavDropdown.Item><Link to="/advance">Advance Payment</Link></NavDropdown.Item>
            </NavDropdown>
           
            <NavDropdown title="Register" id="collasible-nav-dropdown"  disabled={ disabledField ? 'disabled' : null}  >
              <NavDropdown.Item > <Link to="/dairyregister"> Dairy Register </Link></NavDropdown.Item>             
              <NavDropdown.Item > <Link to="/userregister">User Register </Link></NavDropdown.Item>              
            </NavDropdown>
          </Nav>
          <Nav>            
          <Nav.Link eventKey={2}>
             <Link to="/settings" >Settings </Link>
            </Nav.Link>
            <Nav.Link eventKey={2}>
            <Link to="/logout" >  Logout</Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div className='mb-3'>

    </div>
    </>

  );
}

export default Sidebar;