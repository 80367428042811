import React, {Component} from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import Sidebar from '../sidebar/sidebar';


const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
// const dairyId = localStorage.getItem('dairyId');
const config = {
     headers: { Authorization: token,     
      'Access-Control-Allow-Origin' : '*',
      'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
    }   
};

export default class SabhasadEdit extends Component {
  constructor(props){
    super(props);
    this.state={ 
        warnfeedName: "",
        warnfeedRate : "",
    feedName : "",
    feedRate : "",
    udfId : "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  whatsAppNumber: "",
  mobileNumber: "",
  fullAddress : "",
  accountNumber : "",
  accountType : "Saving",
  bankName : "",
  ifscCode : "",
  upiId : "",
  dairyId : "",
  createdBy :"",

  warnudfId : "",
  warnfirstName: "",
  warnlastName: "",
  warnphoneNumber: "",
  warnwhatsAppNumber: "",
  warnmobileNumber: "",
  warnfullAddress : "",
  warnaccountNumber : "",
  warnaccountType : "",
  warnbankName : "",
  warnifscCode : "",
  warnupiId : "",
  danger : "true",
        hasError: false};        
}
async handleInputChange(e) {
  await this.setState({
    [e.target.name] : e.target.value
  }); 
}


componentDidMount() {
  axios.get(baseURL +"sabhasads/"+ window.location.href.split('/')[4], config).then(response => {
  this.setState({
  firstName: response.data.message.firstName,
  lastName : response.data.message.lastName,
  fullAddress : response.data.message.fullAddress,
  phoneNumber : response.data.message.phoneNumber,
  whatsAppNumber : response.data.message.whatsAppNumber,
  mobileNumber : response.data.message.mobileNumber,
  udfId :response.data.message.udfId,
  bankName :response.data.message.bankName,
  ifscCode : response.data.message.ifscCode,
  upiId : response.data.message.upiId,
  accountNumber :response.data.message.accountNumber,
  accountType : response.data.message.accountType,
  });
  });
  }
  

  render() {

    const bodyParameters = {
        firstName: this.state.firstName,
        lastName : this.state.lastName,
        fullAddress : this.state.fullAddress,
        phoneNumber : this.state.phoneNumber,
        whatsAppNumber : this.state.whatsAppNumber,
        mobileNumber : this.state.mobileNumber,
        udfId :this.state.udfId,
        bankName :this.state.bankName,
        ifscCode : this.state.ifscCode,
        upiId : this.state.upiId,
        accountNumber :this.state.accountNumber,
        accountType : this.state.accountType,
        updatedBy :localStorage.getItem('userId'),
    }
    
    const submitForm = async (event) =>{
      event.preventDefault();
      axios
      .put(baseURL + "sabhasads/" +window.location.href.split('/')[4] ,  
       bodyParameters, config)
      .then(data =>{
        console.log("data" + data);
            if(data.data.statuscode === "200"){        
              window.location.href = '/sabhasadlist';
          } 
        })
      .catch(error => console.log(error));
    }

    return(
      <>
      <Sidebar />
       <div className="container">
       <div className="card">          
        <div className="card-body form">
        <form autoComplete="off"  onSubmit={submitForm}>        
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Sabhasad Number</label>
                                <input className={` ${this.state.warnudfId ? "warning" : "form-control" }`} type="text" placeholder="Enter Sabhasad Id" autoComplete="off" name="udfId" value={this.state.udfId || ''} readOnly />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Sabhasad First Name </label>
                                <input className={` ${this.state.warnfirstName ? "warning" : "form-control" }`} type="text" placeholder="Enter First Name" autoComplete="off" name="firstName" value={this.state.firstName || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Sabhasad Last name</label>
                                <input className={` ${this.state.warnlastName ? "warning" : "form-control" }`} type="text" placeholder="Enter Last Name" autoComplete="off" name="lastName" value={this.state.lastName || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col> 
        </Row> 
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Mobile Number </label>
                                <input className={` ${this.state.warnmobileNumber ? "warning" : "form-control" }`} type="text" placeholder="Enter Mobile Number" autoComplete="off" name="mobileNumber" value={this.state.mobileNumber || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Mobile.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Phone Number</label>
                                <input className={` ${this.state.warnphoneNumber ? "warning" : "form-control" }`} type="text" placeholder="Enter Phone Number" autoComplete="off" name="phoneNumber" value={this.state.phoneNumber || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> WhatsApp Number</label>
                                <input className={` ${this.state.warnwhatsAppNumber ? "warning" : "form-control" }`} type="text" placeholder="Enter WhatsApp Number" autoComplete="off" name="whatsAppNumber" value={this.state.whatsAppNumber || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col> 
        </Row> 
        <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Full Address</label>
                                <input className={` ${this.state.warnfullAddress ? "warning" : "form-control" }`} type="text" placeholder="Enter Full Address" autoComplete="off" name="fullAddress" value={this.state.fullAddress || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Mobile.</p>
                            </div>                         
                    </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Bank Name</label>
                                <input className={` ${this.state.warnbankName ? "warning" : "form-control" }`} type="text" placeholder="Enter Bank Name" autoComplete="off" name="bankName" value={this.state.bankName || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Mobile.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Account Number </label>
                                <input className={` ${this.state.warnaccountNumber ? "warning" : "form-control" }`} type="text" placeholder="Enter Account Number" autoComplete="off" name="accountNumber" value={this.state.accountNumber || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> IFSC Code </label>
                                <input className={` ${this.state.warnifscCode ? "warning" : "form-control" }`} type="text" placeholder="Enter IFSC Code" autoComplete="off" name="ifscCode" value={this.state.ifscCode || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a IFSC Code</p>
                            </div>
                    </div>
            </Col>  
             
        </Row>
        <Row>
        <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Account Type</label>
                                <input className={` ${this.state.warnaccountType ? "warning" : "form-control" }`} type="text" placeholder="Enter Account Type" autoComplete="off" name="accountType" value={this.state.accountType || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> UPI Id </label>
                                <input className={` ${this.state.warnupiId ? "warning" : "form-control" }`} type="text" placeholder="Enter UPI Id" autoComplete="off" name="upiId" value={this.state.upiId || ''} onChange={e  => this.handleInputChange(e)} />
                                <p className={` ${this.state.danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Mobile.</p>
                            </div>                         
                    </div>
            </Col>
                       
        </Row>
            <div>
                <button type="submit" className="btn btn-primary">Update Sabhasad</button>
            </div>
        </form>
        </div>
        </div>
      
      </div>
    </>
  );
  }
    
}